import { Injectable } from "@angular/core";
import { CommonApi } from "../apis/common.api";
import { ContentManagementApi } from "../apis/content-management-api.service";
import { AuthService } from "./auth.service";

@Injectable()
export class FilterRoleService {
    private userRole : string;
    constructor(
        protected userData: AuthService,
        protected commonApi: CommonApi,
        protected contentApi : ContentManagementApi
    ) { 
        this.userRole = this.userData.currentUserValue["roles"][0].name;
    }
    getUserRole(){
        return this.userRole;
    }
    getUserRoles() {
        if(['ADMIN', 'TECH'].includes(this.userRole)){
            return this.commonApi.getRoleList();
        }
        else{
            return this.commonApi.getRoleByName(this.userRole);
        }
    }

    getFilteredListContent(){
        if(['ADMIN', 'TECH'].includes(this.userRole)){
            return this.contentApi.getListContent('mailing-lists-config', [`filter={"order": "favourite DESC" }`])
        }
        else{
            return this.contentApi.getListContent('mailing-lists-config', [`filter={"where":{"role": "${this.userRole}"},"order": "favourite DESC" }`])
        }
    }
}