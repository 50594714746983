<div class="grid">
  <div class="col-12">
    <media-upload-ics
      (uploadCompleted)="updateDataView()"
      [allFolder]="icsList"
      [currentFolder]="filterByFolder"
    >
    </media-upload-ics>
  </div>
  <div class="col-12">
    <p-dataView
      *ngIf="visible"
      #dv
      [value]="icsList"
      layout="list"
      filterBy="title,alt"
      [lazy]="true"
      (onLazyLoad)="lazyLoadingIcs($event)"
      [paginator]="true"
      [rows]="12"
      [totalRecords]="totalRecords"
      [loading]="loading"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
    >
      <p-header>
        <div class="ui-helper-clearfix">
          <div class="grid justify-content-between">
            <div class="sm:col-12 md:col-4">
              <input
                type="text"
                pInputText
                [placeholder]="'common.actions.search' | translate | titlecase"
                [(ngModel)]="filterBy"
              />
            </div>
            <div class="sm:col-12 md:col-4">
              <p-dropdown
                [pTooltip]="'media.sortHint' | translate | titlecase"
                tooltipPosition="top"
                [options]="sortOptions"
                [placeholder]="'common.actions.sortBy' | translate | titlecase"
                (onChange)="onSortChange($event)"
              >
              </p-dropdown>
            </div>
            <div class="sm:col-12 md:col-4 flex justify-content-end">
              <button
                pButton
                type="button"
                [label]="'common.actions.search' | translate | titlecase"
                icon="fa fa-search"
                (click)="updateDataView()"
              ></button>
            </div>
          </div>
          <div class="grid">
            <div class="sm:col-12 md:col-6">
              <span
                *ngIf="filterByFolder.length > 0"
                style="font-size: x-large;"
              >
                <i class="fa fa-folder-open" aria-hidden="true"></i
                ><span style="margin: 10px;">{{ filterByFolder }}</span>
              </span>
            </div>
            <div class="sm:col-12 md:col-6 flex justify-content-end">
              <button
                pButton
                type="button"
                icon="fa {{
                  filterByFolder.length > 0 ? 'fa-level-up-alt' : 'fa-sync'
                }}"
                [label]="
                  (filterByFolder.length > 0
                    ? 'common.actions.moveFolderUp'
                    : 'common.actions.clearFilters'
                  )
                    | translate
                    | titlecase
                "
                class="p-button-secondary"
                (click)="onClearFilters()"
              ></button>
            </div>
          </div>
        </div>
      </p-header>

      <ng-template let-ics pTemplate="listItem">
        <div class="col-12" *ngIf="filterByFolder.length > 0" id="{{ ics.id }}">
          <div class="grid">
            <div class="col-1 flex justify-content-center align-items-center">
              <a href="{{ ics.src }}" target="_blank" rel="noreferrer">
                <img src="assets/img/common/ics_icon.png" width="50" />
              </a>
            </div>
            <div class="col-4">
              <b>File Name: </b
              >{{ ics.originalFileName ? ics.originalFileName : ics.fileName
              }}<br />
              <b>Upload Date: </b
              ><i>{{ ics.createdAt | date: 'dd/MM/y hh:mm a' }}</i>
            </div>
            <div class="col-4"><b>Shared with: </b>{{ ics.roles }}</div>
            <div
              class="col-3 flex justify-content-end align-items-center justify-content-center"
            >
              <button
                pButton
                type="button"
                icon="fa fa-trash"
                class="p-button-danger"
                [label]="'common.actions.delete' | translate | titlecase"
                (click)="showConfirm(ics.id)"
                style="margin-right: 1rem;"
              ></button>
              <button
                *ngIf="selectIcsEnabled"
                pButton
                type="button"
                icon="fa fa-check"
                [label]="'common.actions.select' | translate | titlecase"
                (click)="onIcsClick(ics)"
              ></button>
            </div>
          </div>
        </div>

        <div
          *ngIf="filterByFolder.length === 0"
          id="{{ ics.id }}"
          (dblclick)="open(ics.folder)"
          style="padding: .5em; border-radius: 5px; cursor: pointer"
          class="col-12"
        >
          <div class="grid">
            <div class="col-1 flex justify-content-center align-items-center">
              <i class="fa fa-folder-open fa-2x" aria-hidden="true"></i>
            </div>
            <div class="col-7 flex align-items-center">
              <b>Folder Name: </b>{{ ics.folder }}
            </div>
            <div class="col-4 flex justify-content-end">
              <button
                pButton
                type="button"
                class="p-button-secondary"
                icon="fa fa-folder-open"
                [label]="'common.actions.open' | translate | titlecase"
                (click)="open(ics.folder)"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>

<p-confirmDialog #confirm key="confirm">
  <ng-template let-message pTemplate="message">
    <p style="white-space: pre;">{{ message }}</p>
  </ng-template>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="onConfirm()"
      label="Yes"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      (click)="onReject()"
      label="No"
      class="p-button-secondary"
    ></button>
  </p-footer>
</p-confirmDialog>

