import { SplitStringPipe } from './pipes/split-string.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceModule } from './service.module';
import { CredentialsInterceptor } from './interceptors/credentials.interceptor';
import { UrlInterceptor } from './interceptors/url.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ApiModule } from './api.module';
import { ResolverModule } from './resolver.module';
import { TemplateHostDirective } from './dynamic-components/directives/template-host.directive';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
  declarations: [TemplateHostDirective, SplitStringPipe],
  exports: [TemplateHostDirective, NgHttpLoaderModule, SplitStringPipe],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ResolverModule,
    NgHttpLoaderModule.forRoot(),
    ServiceModule.forRoot(),
    ApiModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
