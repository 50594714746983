<div class="grid">
  <div class="col-12">
    <media-upload-video
      (uploadCompleted)="updateDataView()"
      [allFolder]="videoList"
      [currentFolder]="filterByFolder"
    >
    </media-upload-video>
  </div>
  <div class="col-12">
    <p-dataView
      *ngIf="visible"
      #dv
      [value]="videoList"
      layout="grid"
      filterBy="title,alt"
      [lazy]="true"
      (onLazyLoad)="lazyLoadingVideo($event)"
      [paginator]="true"
      [rows]="12"
      [totalRecords]="totalRecords"
      [loading]="loading"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
    >
      <p-header>
        <div class="ui-helper-clearfix">
          <div class="grid justify-content-between">
            <div class="sm:col-12 md:col-3">
              <input
                type="text"
                pInputText
                [placeholder]="'common.actions.search' | translate | titlecase"
                [(ngModel)]="filterBy"
              />
            </div>
            <div class="sm:col-12 md:col-3">
              <p-multiSelect
                [options]="tagOptions"
                [defaultLabel]="
                  'common.actions.typeOfContent' | translate | titlecase
                "
                [(ngModel)]="filterByTag"
                [filter]="false"
                [maxSelectedLabels]="2"
                [selectedItemsLabel]="'{0} items selected'"
                styleClass="multiselect-mobileregulation-width"
              ></p-multiSelect>
            </div>
            <div class="sm:col-12 md:col-3">
              <p-dropdown
                [pTooltip]="'media.sortHint' | translate | titlecase"
                tooltipPosition="top"
                [options]="sortOptions"
                [placeholder]="'common.actions.sortBy' | translate | titlecase"
                (onChange)="onSortChange($event)"
              >
              </p-dropdown>
            </div>
            <div class="sm:col-12 md:col-3 flex justify-content-end">
              <button
                pButton
                type="button"
                [label]="'common.actions.search' | translate | titlecase"
                icon="fa fa-search"
                (click)="updateDataView()"
              ></button>
            </div>
          </div>
          <div class="grid">
            <div class="sm:col-12 md:col-6">
              <span
                *ngIf="filterByFolder.length > 0"
                style="font-size: x-large;"
              >
                <i class="fa fa-folder-open" aria-hidden="true"></i
                ><span style="margin: 10px;">{{ filterByFolder }}</span>
              </span>
            </div>
            <div class="sm:col-12 md:col-6 flex justify-content-end">
              <button
                pButton
                type="button"
                icon="fa {{
                  filterByFolder.length > 0 ? 'fa-level-up-alt' : 'fa-sync'
                }}"
                [label]="
                  (filterByFolder.length > 0
                    ? 'common.actions.moveFolderUp'
                    : 'common.actions.clearFilters'
                  )
                    | translate
                    | titlecase
                "
                class="p-button-secondary"
                (click)="onClearFilters()"
              ></button>
            </div>
          </div>
        </div>
      </p-header>

      <ng-template let-video pTemplate="gridItem">
        <div
          *ngIf="filterByFolder.length > 0"
          id="{{ video.id }}"
          (click)="onVideoClick(video.id)"
          style="padding: .5em; border-radius: 5px; cursor: pointer"
          [style.border]="isSelected(video.id)"
          class="col-12 md:col-4"
        >
          <p-panel
            [header]="
              video.title
                ? video.title
                : video.originalFileName
                ? video.originalFileName
                : video.fileName
            "
            [style]="{
              'font-size': '.8em',
              'text-align': 'center',
              'word-break': 'break-all'
            }"
          >
            <video
              id="{{ video.id }}"
              controls
              preload="auto"
              width="200"
              height="120"
              data-setup="{}"
            >
              <source src="{{ video.src }}" type="video/mp4" />
            </video>
            <hr class="ui-widget-content" style="border-top:0" />
            <div class="image-detail">
              <div *ngIf="video.title">
                <b>{{ 'common.labels.title' | translate | titlecase }} </b>
                <i>{{ video.title }}</i>
              </div>
              <div *ngIf="video.description">
                <b
                  >{{ 'common.labels.description' | translate | titlecase }}
                </b>
                <i>{{ video.description }}</i>
              </div>
              <div>
                <b>{{ 'common.labels.published' | translate | titlecase }} </b>
                <i>{{ video.createdAt | date: 'dd/MM/y hh:mm a' }}</i>
              </div>
              <div>
                <b
                  >{{ 'common.labels.lastModified' | translate | titlecase }}
                </b>
                <i>{{ video.updatedAt | date: 'dd/MM/y hh:mm a' }}</i>
              </div>
            </div>
          </p-panel>
        </div>

        <div
          *ngIf="filterByFolder.length === 0"
          id="{{ video.id }}"
          style="padding: .5em; border-radius: 5px; "
          class="col-12 md:col-4"
          [style.border]="isSelected(video.id)"
        >
          <p-panel
            [header]="video.folder"
            [style]="{
              'font-size': '.8em',
              'text-align': 'center',
              'word-break': 'break-all'
            }"
          >
            <div class="grid">
              <div class="col-12">
                <i
                  class="fa fa-folder"
                  style="font-size: 15em;"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="col-12">
                <button
                  pButton
                  type="button"
                  icon="fa fa-folder-open"
                  [label]="'common.actions.open' | translate | titlecase"
                  class="p-button-secondary flex-1"
                  (click)="onImageDoubleClick(video)"
                ></button>
              </div>
            </div>
          </p-panel>
        </div>
      </ng-template>
    </p-dataView>


  </div>
</div>

  <!--   <p-toast position="center" key="g" (onClose)="onReject()" [modal]="true" [baseZIndex]="9000">
     //TODO modal remove primeng version-->
  <p-toast position="center" key="g" (onClose)="onReject()" [baseZIndex]="9000">
    <ng-template let-message pTemplate="message">
      <div style="text-align: center">
        <i
          class="pi pi-exclamation-triangle"
          style="font-size: 3em; color: grey"
        ></i>
        <h3>{{ message.summary }}</h3>
        <p>{{ message.detail }}</p>
      </div>
      <div class="ui-g ui-fluid">
        <div class="col-6">
          <button
            type="button"
            pButton
            (click)="onConfirm()"
            label="Yes"
            class="btn-info"
          ></button>
        </div>
        <div class="col-6">
          <button
            type="button"
            pButton
            (click)="onReject()"
            label="No"
            class="btn-primary"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-toast>
