import { Injectable, Injector } from '@angular/core';
import { CommonApi } from '../apis/common.api';
import { Router } from '@angular/router';
import { AppDataService } from './app-data.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
@Injectable()
export class MenuService {
  private static activeSection$;
  get activeSection() {
    return MenuService.activeSection$;
  }
  private static menu;

  protected parentsRoutes = [];

  constructor(
    protected injector: Injector,
    protected appData: AppDataService,
    protected commonApi: CommonApi
  ) {
    if (!MenuService.activeSection$) {
      MenuService.activeSection$ = new BehaviorSubject<any>(null);
    }
  }

  get router() {
    return this.injector.get(Router);
  }

  parseMenu(menuItem, currentLanguage = 'it') {
    const label = menuItem
      ? (menuItem.translations &&
          menuItem.translations[`${currentLanguage}Label`]) ||
        menuItem.label
      : null;
    const currentMenuItem = {
      label,
      icon: menuItem && menuItem.icon ? 'fa fa-' + menuItem.icon : null,
      data: {
        type: menuItem && menuItem.type ? menuItem.type : null,
        endpoint: menuItem && menuItem.endpoint ? menuItem.endpoint : null
      }
    };

    switch (menuItem.type) {
      case 'group':
        menuItem.items.forEach(() => {
          if (menuItem.route !== '/') {
            this.parentsRoutes.push(menuItem.route);
          }
        });
        const itemsGroup = [];
        menuItem.items.map(item => {
          if (item !== null) {
            itemsGroup.push(this.parseMenu(item));
          }
        });
        currentMenuItem['items'] = itemsGroup;
        break;
      case 'title':
        currentMenuItem['styleClass'] = 'titleItem';
        currentMenuItem['separator'] = true;
        currentMenuItem['disabled'] = true;
        break;
      case 'link':
        currentMenuItem['url'] = menuItem.url ? menuItem.url : null;
        currentMenuItem['target'] = menuItem.target ? menuItem.target : null;
        this.parentsRoutes.pop();
        break;
      case 'main':
        currentMenuItem['routerLink'] = '/back-office';
        this.parentsRoutes.pop();
        break;
      case null:
        break;
      default:
        let parents = Array.from(new Set(this.parentsRoutes)).join('/');
        currentMenuItem['routerLink'] = menuItem.route
          ? parents + '/' + menuItem.route
          : null;
        currentMenuItem['command'] = () => {
          this.setActivatedRouteData(menuItem.endpoint, menuItem.type);
          MenuService.activeSection$.next(currentMenuItem);
        };
        this.parentsRoutes.pop();
    }
    return currentMenuItem;
  }

  async getMenu() {
    try {
      const primeNgMenu = [];
      const roles: any = this.appData.getAppData('currentUser').roles;
      // filter menu with user roles
      let userRoles = roles.map(role => role.id);
      let menu: any = await lastValueFrom(this.commonApi.getMenu());
      let menuLV1 = menu;
      for (var indexLV1 = 0; indexLV1 < menuLV1.length; indexLV1++) {
        let menuItemLV1 = menuLV1[indexLV1];
        const userRoleFoundLV1 = menuItemLV1.roleIds.some(
          r => userRoles.indexOf(r) >= 0
        );
        if (!userRoleFoundLV1) delete menuLV1[indexLV1];
        else {
          // console.log( menuItemLV1 );
          let menuLV2 = menuLV1[indexLV1]['items'];
          for (var indexLV2 = 0; indexLV2 < menuLV2.length; indexLV2++) {
            let menuItemLV2 = menuLV2[indexLV2];
            const userRoleFoundLV2 = menuItemLV2.roleIds.some(
              r => userRoles.indexOf(r) >= 0
            );
            if (!userRoleFoundLV2) delete menuLV1[indexLV1]['items'][indexLV2];
            else {
              // console.log( menuItemLV2 );
              let menuLV3 = menuLV1[indexLV1]['items'][indexLV2]['items'];
              for (var indexLV3 = 0; indexLV3 < menuLV3.length; indexLV3++) {
                let menuItemLV3 = menuLV3[indexLV3];
                const userRoleFoundLV3 = menuItemLV3.roleIds.some(
                  r => userRoles.indexOf(r) >= 0
                );
                if (!userRoleFoundLV3)
                  delete menuLV1[indexLV1]['items'][indexLV2]['items'][
                    indexLV3
                  ];
                else {
                  // console.log( menuItemLV3 );
                }
              }
            }
          }
        }
      }
      menu = menu.filter(Boolean);

      const currentLanguage = this.appData.getAppData('cmsLanguage');
      menu.forEach((item: any) => {
        this.parentsRoutes = [];
        if (item !== null || item !== undefined) {
          primeNgMenu.push(this.parseMenu(item, currentLanguage));
        }
      });

      MenuService.menu = primeNgMenu;
      // Active section initialization
      const currentSection = this.getSectionByRoute(this.router.url);
      MenuService.activeSection$.next(currentSection);

      return primeNgMenu;
    } catch (error) {
      console.error(error);
    }
  }

  setActivatedRouteData(endpoint, type) {
    this.appData.setAppData('routerData', [endpoint, type]);
  }

  getSectionByRoute(route: string) {
    return MenuService.menu.find(menuItem => {
      if (menuItem.items && menuItem.items.length) {
        return menuItem.items.find(
          subMenuItem => subMenuItem.routerLink === route
        );
      }

      return menuItem.routerLink === route;
    });
  }

  getSectionByType(type: string) {
    return MenuService.menu.find(menuItem => {
      if (menuItem.items && menuItem.items.length) {
        return menuItem.items.find(
          subMenuItem => subMenuItem.data.type === type
        );
      }

      return menuItem.data.type === type;
    });
  }

  async getSectionsByEndpoint(endpoint) {
    try {
      let sections = [];
      MenuService.menu.forEach(menuItem => {
        if (menuItem.items && menuItem.items.length) {
          sections = sections.concat(
            menuItem.items.filter(
              subMenuItem => subMenuItem.data.endpoint === endpoint
            )
          );
        }
        if (menuItem.data && menuItem.data.endpoint === endpoint) {
          sections.push(menuItem);
        }
      });

      return sections;
    } catch (err) {
      console.log(err);
    }
  }

  getRouteByType(type) {
    let route;
    MenuService.menu.forEach(menuItem => {
      if (menuItem.items && menuItem.items.length) {
        const childMenuItem = menuItem.items.find(
          subMenuItem => subMenuItem.data.type === type
        );
        if (childMenuItem) {
          route = childMenuItem.routerLink;
        }
      }
      if (menuItem.data && menuItem.data.type === type) {
        route = menuItem.routerLink;
      }
    });
    return route;
  }

  // WORKAROUND: the normal navigation strategy through Angular Router does not update the menu, this is why we need this method
  // The sections of this CMS strongly depend on this component - stores information about current route in app data service
  // If the navigation is performed without going through the menu, the cms-list component does not work because the endpoint is missing: it is placed in this service...
  programmaticallySelectSectionByType(type) {
    const menuItem = this.getSectionByType(type);
    // Replication of "command", see "parseMenu"
    this.setActivatedRouteData(menuItem.data.endpoint, menuItem.data.type);
    MenuService.activeSection$.next(menuItem);
  }

  getSectionLabelByRoute(route: string){
    try {
      const {label} = this.getSectionByRoute(route.replace(/\/(new|edit\/[a-zA-Z0-9]+)/, '')) || {};
      if(label) return label;
      return 'CK Editor'
    } catch (error) {
      return 'CK Editor'
    }
  }
}
