import { Component } from '@angular/core';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { MenuService } from 'src/app/core/services/menu.service';

@Component({
  selector: 'app-page-with-section-title',
  templateUrl: './page-with-section-title.component.html',
  styleUrls: ['./page-with-section-title.component.scss']
})
export class PageWithSectionTitleComponent {
  title: string;
  activeSectionSubscription: Subscription;

  constructor(protected menuService: MenuService) {
    this.activeSectionSubscription = this.menuService.activeSection
      .pipe(first(value => !!value)) // No need to manually cleanup
      .subscribe(currentMenuItem => {
        if (currentMenuItem) {
          this.title = currentMenuItem.label;
        }
      });
  }
}
