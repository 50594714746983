import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import { MenuService } from './services/menu.service';
import { RouteDataService } from './services/route-data.service';
import { SchemasService } from './services/schemas.service';
import { LiveChangesService } from './services/live-changes.service';
import { InputOptionService } from './services/input-option.service';
import { VersionCheckService } from './services/version-check.service';
import { SlugService } from './services/slug.service';
import { TemplateHostService } from './services/template-host.service';
import { MailService } from './services/mail/mail.service';
import { BlockGeneratorService } from './services/mail/block-generator.service';
import { AppDataService } from './services/app-data.service';
import { FilterRoleService } from './services/filtered-roles.service';
import { MailTemplateService } from './services/mail-templates/mail-template.service';
import { JsonContentService } from './services/json-content.service';
import { InactivityService } from './services/inactivity.service';
import { ApprovalFlowService } from './services/approval-flow.service';
import { DataStampaGeneratorService } from './services/mail/data-stampa-generator.service';
import { AdnkGeneratorService } from './services/mail/adnk-generator.service';
import { LaBussolaGeneratorService } from './services/mail/la-bussola-generator.service';
@NgModule({
  imports: [CommonModule],
  providers: [
    AuthService,
    InputOptionService,
    LiveChangesService,
    DataStampaGeneratorService,
    AdnkGeneratorService,
    LaBussolaGeneratorService,
    BlockGeneratorService,
    MailService,
    MenuService,
    RouteDataService,
    SchemasService,
    SlugService,
    TemplateHostService,
    VersionCheckService,
    FilterRoleService,
    MailTemplateService,
    JsonContentService,
    InactivityService,
    ApprovalFlowService
  ]
})
export class ServiceModule {
  static forRoot(): ModuleWithProviders<ServiceModule> {
    return {
      ngModule: ServiceModule,
      providers: [{ provide: AppDataService, useClass: AppDataService }]
    };
  }
}
