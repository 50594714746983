import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MenuService } from 'src/app/core/services/menu.service';
import { PageWithSectionTitleComponent } from 'src/app/shared/components/page-with-section-title/page-with-section-title.component';
import { AppDataService } from '../../../core/services/app-data.service';
const env = require('src/environments/environment');

@Component({
  selector: 'media-panel',
  templateUrl: './media-panel.component.html',
  styleUrls: ['./media-panel.component.scss']
})
export class MediaPanelComponent extends PageWithSectionTitleComponent implements OnInit {
  @Input() selectImageEnabled: boolean;
  @Output() imageSelected = new EventEmitter<any>();

  imageData: any;
  cmsLanguage: string;
  sysLanguage: any;
  visible: boolean;

  containerName: string;

  constructor(protected appData: AppDataService, protected menuService: MenuService) {
    super(menuService);
  }

  ngOnInit(): void {
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);

    this.containerName = env.environment.s3Bucket;
  }

  onSelectImage() {
    if (this.selectImageEnabled && this.imageData !== undefined) {
      this.imageSelected.emit(this.imageData);
    }
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe(language => {
      this.sysLanguage = language;
      this.visible = true;
    }); */
    this.visible = true;
  }
}
