import { Component } from '@angular/core';
const env = require('src/environments/environment');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  version = env.environment.version;
  packageName = env.environment.name;
  build = env.environment.build;
  prod = env.environment.production;
}
