<div class="errorPage">
  <div class="errorContent">
    <div class="errorHeader">
      <h1>{{ code || 404}}</h1>
    </div>
    <div class="errorBody">
      <h2>{{ code ? ('common.errors.403.title' | translate) : ('common.errors.404.title' | translate) }}</h2>
      <p>{{ code ? ('common.errors.403.text' | translate) : ('common.errors.404.text' | translate) }}</p>
    </div>
    <div class="errorActions">
      <a class="btn btn-primary btn-block" href="./back-office">
        {{ 'common.errors.404.actions.back' | translate | uppercase }}
      </a>
    </div>
  </div>
</div>