import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
const env = require('src/environments/environment');
import { MessageService } from 'primeng/api';

@Injectable()
export class VersionCheckService {
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(private http: HttpClient, protected router: Router, protected messageService: MessageService) {}
  public initVersionCheck(url) {
    this.checkVersion(url);
  }

  private checkVersion(url) {
    this.http.get(url + '?t=' + new Date().getTime()).subscribe(
      (response: any) => {
        const hash = response.hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        if (env.environment && env.environment.envName !== 'development' && hashChanged) {
          this.router.navigate(['wrong-version']);
        }
        this.currentHash = hash;
      },
      err => {
        console.error(err, 'Could not get version');
      }
    );
  }

  private hasHashChanged(currentHash, newHash) {
    return currentHash !== newHash;
  }
}
