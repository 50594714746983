import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './videoPlayer.component.html',
  styleUrls: ['./videoPlayer.component.scss']
})
export class VideoPlayerComponent {
  constructor(private titleService: Title) {}

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  private readonly PARTNER_ID = '648';
  private readonly UI_CONF_ID = '23460657';


  async ngOnInit(): Promise<void> {
    try {
      await this.loadScript(`https://api.irp2.ovp.kaltura.com/p/${this.PARTNER_ID}/embedPlaykitJs/uiconf_id/${this.UI_CONF_ID}`);

      if (!(window as any).KalturaPlayer) {
        throw new Error('Error KalturaPlayer loading .');
      }

      const entryIdCod = this.getQueryStringValue('entryId');

      this.loadVideo(entryIdCod);
      this.setTitle('Fincantieri Video Player');
    } catch (err) {
      console.error('Error init video:', err);
    }
  }

  private async loadScript(src: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = (err) => reject(new Error(`Error loading script: ${src}`));
      document.body.appendChild(script);
    });
  }

  private getQueryStringValue(name: string): string {
    const regex = new RegExp(`[?&]${name}=([^&#]*)`, 'i');
    const results = regex.exec(window.location.href);
    return results ? results[1] : '';
  }

  // Metodo per configurare e caricare il video
  private loadVideo(entryIdCod: string): void {
    try {
      const KalturaPlayer = (window as any).KalturaPlayer;
      if (!KalturaPlayer) {
        throw new Error('KalturaPlayer not found.');
      }

      const kalturaPlayer = KalturaPlayer.setup({
        targetId: 'kaltura_player',
        provider: {
          partnerId: this.PARTNER_ID,
          uiConfId: this.UI_CONF_ID,
        },
      });

      if (!kalturaPlayer) {
        throw new Error('Error setup.');
      }

      kalturaPlayer.loadMedia({ entryId: entryIdCod });
    } catch (e) {
      console.error('Errore loading video:', e.message);
    }
  }
}
