import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../core/services/menu.service';

@Component({
  selector: 'panel-menu',
  templateUrl: './panel-menu.component.html',
  styleUrls: ['./panel-menu.component.scss'],
})
export class PanelMenuComponent implements OnInit {
  menu: any[];

  // ---

  constructor(protected menuService: MenuService) {}

  // ---

  ngOnInit(): void {
    this.loadMenu();
  }

  // ---

  async loadMenu() {
    this.menu = await this.menuService.getMenu();
  }
}
