import { Injectable } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { Observable, of, from, lastValueFrom, throwError } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  toArray,
  concatMap,
  tap,
  shareReplay,
  take
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageParserService {
  constructor(private mediaApi: MediaApi) {}

  findPattern(obj, pattern, results = [], path = '') {
    for (const key in obj) {
      const value = obj[key];
      const fullPath = path ? `${path}.${key}` : key;

      if (typeof value === 'string') {
        const matches = [...value.matchAll(pattern)];
        if (matches.length > 0) {
          results.push({
            key: fullPath,
            matches: matches.map(m => ({
              match: m[0],
              groups: m.slice(1),
              index: m.index
            }))
          });
        }
      } else if (Array.isArray(value)) {
        value.forEach((element, index) => {
          this.findPattern(element, pattern, results, `${fullPath}[${index}]`);
        });
      } else if (typeof value === 'object' && value !== null) {
        this.findPattern(value, pattern, results, fullPath);
      }
    }
    return results;
  }
  /*
  private base64ToBlob(base64: string, mimeType: string): Blob | null {
    try {
      const base64Data = base64.split(',')[1] || base64;

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteNumbers], { type: mimeType });

      this.blobToBase64(blob)
        .then(convertedBase64 => {
          console.log('Original Base64:', base64);
          console.log('Converted Base64:', convertedBase64);

          if (base64 === convertedBase64) {
            console.log('Base64 conversion is correct!');
          } else {
            console.log('Base64 conversion mismatch!');
          }
        })
        .catch(error => {
          console.error('Error while converting blob to base64:', error);
        });

      return blob;
    } catch (error) {
      console.error('Errore nella conversione base64ToBlob:', error);
      return null;
    }
  } */

  private base64ToBlob(
    base64: string,
    fileName: string,
    contentType: string
  ): File {
    try {
      const byteCharacters = atob(base64);
      const byteArrays = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i);
      }

      return new File([byteArrays], fileName, {
        type: contentType,
        lastModified: Date.now()
      });
    } catch (error) {
      console.error('Errore nella conversione base64ToFile:', error);
      return null;
    }
  }

  blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  base64ToBuffer(base64String) {
    return Buffer.from(base64String, 'base64');
  }

  updateValue(obj, uploadedImages) {
    uploadedImages.forEach(({ key, originalTag, newFilename }) => {
      let ref = obj;
      const keys = key.replace(/\[(\d+)\]/g, '.$1').split('.');

      for (let i = 0; i < keys.length - 1; i++) {
        ref = ref[keys[i]];
      }

      const lastKey = keys[keys.length - 1];

      if (typeof ref[lastKey] === 'string') {
        ref[lastKey] = ref[lastKey].replace(
          originalTag,
          `src="https://medias.cms.dev.fincantieri.it/${newFilename}"`,
        );
      }
    });

    return obj;
  }

  updateBase64Images(
    htmlContent: any,
    folder: string,
    mediaConfig: any
  ): Observable<any> {

    const imgRegex =
      /src=(?:"|&quot;|\\")data:image\/(png|jpeg|jpg);base64,([A-Za-z0-9+/]+={0,2})(?:"|&quot;|\\")/gi;


    const images = this.findPattern(htmlContent, imgRegex);

    if (images.length === 0) {
      return of(htmlContent);
    }

    const uploadedImages: any[] = [];

    return from(images).pipe(
      concatMap(image =>
        from(image.matches).pipe(
          concatMap((match: any) => {
            const fileType = match.groups[0];
            const base64Data = match.groups[1];
            const originalTag = match.match;

            const fileName = `image_${Date.now()}_${Math.floor(Math.random() * 1000)}.${fileType}`;

            const fileBlob = this.base64ToBlob(
              base64Data,
              fileName,
              `image/${fileType}`
            );

            if (!fileBlob || fileBlob.size === 0) {
              console.error('Errore: Il fileBlob è vuoto!');
              return throwError(
                () => new Error('Errore: Il file generato è vuoto.')
              );
            }

            return this.mediaApi
              .getPresignedUrl(fileName, `image/${fileType}`, fileBlob.size)
              .pipe(
                take(1),
                tap((presignedUrlResp: any) => {
                  uploadedImages.push({
                    key: image.key,
                    originalTag,
                    newFilename: presignedUrlResp.newFilename
                  });
                }),
                map(presignedUrlResp => ({
                  fileBlob,
                  presignedUrlResp,
                  fileName
                }))
              );
          }),
          concatMap(({ fileBlob, presignedUrlResp, fileName }) =>
            this.mediaApi.postFileToS3(presignedUrlResp.url, fileBlob).pipe(
              take(1),
              map(() => ({ fileBlob, presignedUrlResp, fileName })),
              catchError(error => {
                console.error('Errore durante l’upload su S3:', error);
                return throwError(() => new Error('Upload su S3 fallito'));
              })
            )
          ),
          concatMap(({ presignedUrlResp, fileBlob }) => {
            const mediaData = {
              containerName: mediaConfig.containerName,
              fileName: presignedUrlResp.newFilename,
              type: fileBlob.type,
              folder: folder,
              roles: mediaConfig.selectedRoles,
              originalFileName: `image_${Date.now()}_${Math.floor(Math.random() * 1000)}.${fileBlob.type.split('/')[1]}`,
              size: fileBlob.size,
              userId: mediaConfig.userId,
              roleId: mediaConfig.roleId
            };
            return this.mediaApi.uploadMedia(mediaData).pipe(
              take(1),
              map(() => presignedUrlResp)
            );
          })
        )
      ),
      toArray(),
      map(() => this.updateValue(htmlContent, uploadedImages)),
      shareReplay()
    );
  }

}
