<div class="changePasswordComponent">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">{{ 'changePassword.oldPassword' | translate | titlecase }}</div>
      <div class="col-sm-8"><input pInputText type="password" [(ngModel)]="oldPassword" /></div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-4">{{ 'changePassword.newPassword' | translate | titlecase }}</div>
      <div class="col-sm-8">
        <input pInputText type="password" [(ngModel)]="newPassword" (keyup)="checkPasswordFormat($event)"
          [pTooltip]="'changePassword.passwordHint' | translate" />
        <div *ngIf="checkOldPassword()">
          <br />
          <small>{{ 'changePassword.messages.oldPasswordError.text' | translate }}</small>
        </div>
      </div>
      <div class="col-sm-12">
        <p-message *ngIf="!passwordOK && newPassword !== undefined" severity="error"
          [text]="'changePassword.passwordHint' | translate">
        </p-message>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-4">{{ 'changePassword.repeatPassword' | translate | titlecase }}</div>
      <div class="col-sm-8">
        <input pInputText type="password" [(ngModel)]="confirmPassword" (keyup)="checkNewPassword()"
          [pTooltip]="'changePassword.repeatPassword' | translate | titlecase" />
        <div *ngIf="!isPasswordConfirmed">
          <br />
          <small>{{ 'changePassword.messages.confirmPasswordError.text' | translate }}</small>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-sm-offset-4 col-sm-6">
        <button [disabled]="!isButtonEnabled()" pButton type="button" [label]="'update password' | titlecase"
          class="btn-info" (click)="updatePassword()"></button>
      </div>
    </div>
  </div>
</div>