<div class="wrapperComponent">
  <div class="ui-g ">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="logo" routerLink="/" tabindex="0">
        <img alt="Logo" src="https://www.fincantieri.com/globalassets/common/header/logo_new.png"
          style="max-width: 40%; padding: 20px" />
      </div>
      <div>
        <div class="grid">
          <div class="col-12 p-lg-6">
            <p-card>
              <div class="cardInfoLink">
                <p>Ci dispiace ma il collegamento al file richiesto non è più disponibile.</p>
                <p>Ti invitiamo a contattare direttamente i seguenti riferimenti:</p>
                <h6>UFFICIO STAMPA</h6>
                <ul style="list-style-type: none; padding-inline-start: 10px;">
                  <li><em>+ 39 040 3192225</em></li>
                  <li><em>+ 39 040 3192527 </em></li>
                  <li><a href="mailto:press.office@fincantieri.it">
                      press.office@fincantieri.it</a></li>
                </ul>
              </div>
            </p-card>
          </div>
          <div class="col-12 p-lg-6">
            <p-card>
              <div class="cardInfoLink">
                <p>
                  Sorry, but the link to the requested file has expired.
                </p>
                <p>Please contact us directly at the following references:
                <h6>PRESS OFFICE</h6>
                <ul style="list-style-type: none; padding-inline-start: 10px;">
                  <li><em>+ 39 040 3192225</em></li>
                  <li><em>+ 39 040 3192527 </em></li>
                  <li><a href="mailto:press.office@fincantieri.it">
                      press.office@fincantieri.it</a></li>
                </ul>
              </div>
            </p-card>
          </div>
          <div class="col-12" style="text-align: center; padding-top: 5vh;">
            <img
              src="https://www.fincantieri.com/globalassets/common/sliders/images-common-caption-full-width-sliderblock/controllate-e-collegate/20210608_constellation-fincantieri-group-top.jpg"
              style="width: 100%;">
          </div>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
</div>
<div class="col-12 footer">
  FINCANTIERI S.p.A. - Via Genova, 1 34121 - Trieste
  Registro Imprese Venezia Giulia - n. iscr. e C.F. 00397130584 - P.IVA 00629440322 - Cap. Soc. i.v. €
  862.980.725,70
</div>
