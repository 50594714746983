import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { SystemLanguageComponent } from './components/system-language/system-language.component';
import { ErrorComponent } from './components/shared-error/error.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PanelMenuComponent } from './components/panel-menu/panel-menu.component';
import { JsonEditorComponent } from './components/json-editor/json-editor.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { MediaModule } from './../media/media.module';
import { CoreModule } from './../core/core.module';
import { AgGridModule } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { PrimengModulesModule } from '../primeng-modules/primeng-modules.module';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { LinkExpiredComponent } from '../public/components/public-link-expired/linkExpired.component';
import { PageWithSectionTitleComponent } from './components/page-with-section-title/page-with-section-title.component';
import { RejectNotesComponent } from './components/reject-notes/reject-notes.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { DisplayRejectNotesComponent } from './components/display-reject-notes/display-reject-notes.component';
import { PodacastComponent } from '../public/components/podcast/podcast.component';
import { VideoPlayerComponent } from '../public/components/video-player/videoPlayer.component';

const DECLARATIONS = [
  ChangePasswordComponent,
  CustomLoaderComponent,
  FooterComponent,
  JsonEditorComponent,
  PanelMenuComponent,
  ResetPasswordComponent,
  ErrorComponent,
  SystemLanguageComponent,
  TopBarComponent,
  LinkExpiredComponent,
  PageWithSectionTitleComponent,
  RejectNotesComponent,
  AddressModalComponent,
  DisplayRejectNotesComponent,
  PodacastComponent,
  VideoPlayerComponent
];
const IMPORTS = [
  CommonModule,
  FormsModule,
  RouterModule,
  TranslateModule,

  AgGridModule.withComponents([]),
  PrimengModulesModule,
  CoreModule,
  MediaModule
];
const EXPORTS = [
  ...DECLARATIONS,
  CommonModule,
  FormsModule,
  RouterModule,
  TranslateModule,
  AgGridModule,
  PrimengModulesModule,
  CoreModule,
  MediaModule
];
const PROVIDERS = [];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...EXPORTS],
  providers: [...PROVIDERS]
})
export class SharedModule {}
