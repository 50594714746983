import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LiveChangesService {
  private cmsLanguage = new BehaviorSubject<string>('');
  private selectedUser = new BehaviorSubject<any[]>([]);
  currentLanguage = this.cmsLanguage.asObservable();
  currentSelectedUser = this.selectedUser.asObservable();

  changeLanguage(cmsLanguage: string) {
    this.cmsLanguage.next(cmsLanguage);
  }

  bindSelectedUser(selectedUser: any[]) {
    this.selectedUser.next(selectedUser);
  }
}
