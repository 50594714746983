import { TranslateService } from '@ngx-translate/core';
import { VersionCheckService } from './core/services/version-check.service';
import { Component, OnInit, HostBinding } from '@angular/core';
import { CustomLoaderComponent } from './shared/components/custom-loader/custom-loader.component';
import { AppDataService } from './core/services/app-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('@.disabled')
  public animationsDisabled = true;
  public customLoader = CustomLoaderComponent;

  // ---

  constructor(
    protected versionCheckService: VersionCheckService,
    protected translateService: TranslateService,
    protected appDataService: AppDataService
  ) {}

  // ---

  ngOnInit() {
    this.versionCheckService.initVersionCheck('/assets/version.json');

    this.appDataService.cmsLanguage$.subscribe(lang => {
      setTimeout(() => this.translateService.use(lang), 0);
    });
  }

  ngOnDestroy() {
    this.appDataService.cmsLanguage$.unsubscribe();
  }
}
