import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'display-reject-notes',
  templateUrl: './display-reject-notes.component.html',
  styleUrls: ['./display-reject-notes.component.scss']
})
export class DisplayRejectNotesComponent {
  @Input() rejectNotesVisible: boolean;
  @Output() rejectNotesVisibleChange = new EventEmitter<boolean>();
  @Input() rejectNotes: string;
  @Input() onRejectNotesHide: Function;

  onHide() {
    this.rejectNotesVisibleChange.emit();
    typeof this.onRejectNotesHide === 'function' && this.onRejectNotesHide();
  }
}
