import { Injectable } from '@angular/core';
import { Slug } from 'ng2-slugify';

@Injectable()
export class SlugService {
  private slug = new Slug('default');
  createSlug(s: string): string {
    return this.slug.slugify(s);
  }
}
