import { MessageService } from 'primeng/api';
import { ContentManagementApi } from '../../apis/content-management-api.service';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { BlockGeneratorService } from './block-generator.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class MailService {
  constructor(
    protected contentManagement: ContentManagementApi,
    protected messageService: MessageService,
    protected appDataService: AppDataService,
    protected blockGenerator: BlockGeneratorService
  ) {}

  async sendTestEmail(content, locale, endpoint) {
    try {
      const mailTemplate = await lastValueFrom(this.getMailTemplate(content.content.mail_template, locale));
      const currentUser = this.appDataService.getAppData('currentUser');

      const mailBody = this.blockGenerator.generateMail(
        mailTemplate,
        content.content,
        locale,
        content.id,
        endpoint,
        currentUser
      );
      await lastValueFrom(this.contentManagement.sendTestMail({ slug: content.slug, mailBody }));
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: error.message || 'Error while sending test mail'
      });
    }
  }

  getMailTemplate = (mailTemplateSlug, locale) => {
    return this.contentManagement.getPublishedContentBySlug('mail-templates', mailTemplateSlug, locale);
  };

  previewMail = async (content, locale, comunicationId, endpoint) => {
    const mailTemplate = this.getMailTemplate(content.mail_template, locale);
    mailTemplate.subscribe((mailTemplate: any) => {
      console.log(mailTemplate)
      const currentUser = this.appDataService.getAppData('currentUser');
      let htmlPreview = this.blockGenerator.generateMail(
        mailTemplate,
        content,
        locale,
        comunicationId,
        endpoint,
        currentUser
      );
      var newWindow = window.open();
      // add CKEditor 5 content style
      const str1 = `<link rel="stylesheet" href="https://cdn.ckeditor.com/ckeditor5/42.0.0/ckeditor5-content.css">
              <div class="ck-content">`;
      const str2 = `</div>`;
      htmlPreview = str1.concat(htmlPreview).concat(str2);

      newWindow.document.write(htmlPreview);
    });
  };

  createNewContent(jobData): void {
    this.contentManagement.createNewContent('mailing-lists-jobs', jobData).subscribe(result => {});
  }

  // TODO: (Danilo) define new exchage server to send email
  // Check if the constraints let us to use this server
  createMailJob = (content, locale, sendData, endpoint) => {
    const now = Date.now();
    const jobName = `job_${now}`;

    const mailTemplate = this.getMailTemplate(content.content.mail_template, locale);

    const currentUser = this.appDataService.getAppData('currentUser');
    const jobData = {
      name: jobName,
      status: 'ready',
      creationDate: `${new Date().toISOString()}`,
      contentType: `${sendData[2]}`,
      contentId: `${content.id}`,
      contentTitle: `${content.title}`,
      tolist: `${sendData[0]}`,
      mailBody: '',
      contentUpdatedAt: `${content.updatedAt}`,
      createdBy: {
        role: currentUser.roles[0].name,
        email: currentUser.user.email,
        senderEmail: sendData[4] ? sendData[4].sender : null,
        alias: sendData[4] ? sendData[4].alias : null,
        fullName: currentUser.user.firstName + ' ' + currentUser.user.lastName
      }
    };
    sendData[1] ? (jobData['sendDate'] = sendData[1]) : null;

    mailTemplate.subscribe((mailTemplate: any) => {
      jobData.mailBody += this.blockGenerator.generateMail(
        mailTemplate,
        content.content,
        locale,
        content.id,
        endpoint,
        currentUser
      );
      this.createNewContent(jobData);
    });
  };

  importMailingLists = async (listsArray: any) => {
    // fetch list
    const listsClear = listsArray.filter(element => {
      // check if contains minimal data
      if (element && element['email'] !== undefined && element['list'] !== undefined) {
        element['id'] = btoa(element['email'] + element['list']);
        return element;
      }
    });

    // insert new elements
    let result = null;
    try {
      result = await lastValueFrom(this.contentManagement.bulkUpdateContent('mailing-lists', listsClear));
    } catch (exc) {
      console.log(exc);
    }
    console.log(result);
  };
}
