import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { VersionCheckService } from '../services/version-check.service';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
const env = require('src/environments/environment');
import { AppDataService } from '../services/app-data.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private appData: AppDataService, protected versionCheckService: VersionCheckService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.appData.getAppData('token');
    const apiKey: string = env.environment.apiKey;

    request = request.clone({
      url:
        request.url.includes('/assets/') || request.url.startsWith('https://')
          ? request.url
          : `${env.environment.apiUrl}${request.url}`
    });

    if (request.url.startsWith(env.environment.apiUrl)) {
      request = request.clone({ headers: request.headers.set('Authorization', `${token || undefined}`) });
      request = request.clone({ headers: request.headers.set('apikey', `${apiKey}`) });
      if (request.method === `GET`) {
        // Version check for every GET call
        // this.versionCheckService.initVersionCheck('/assets/version.json');

        const timestamp = request.url.includes('?') ? `&t=${new Date().getTime()}` : `?t=${new Date().getTime()}`;
        request = request.clone({
          url: `${request.url}${request.url.includes('/v2/network-content/medias/resize/') ? '' : timestamp}`
        });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((e: HttpErrorResponse) => {
        return throwError(e.error);
      })
    );
  }
}
