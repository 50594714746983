<div class="grid" *ngIf="visible">
  <div class="col-12 p-md-6">
    <h2>{{ title }}</h2>
  </div>
</div>
<div class="grid">
  <div class="col-12">
    <div class="col-12">
      <div class="col-12">
        <media-ics-view [selectIcsEnabled]="selectIcsEnabled" (refresh)="icsData = $event"
          (icsClicked)="icsData = $event; onSelectIcs()" #dataView>
        </media-ics-view>
      </div>
    </div>
  </div>
</div>
