<br />

<div class="ui-grid-row">
  <div class="ui-grid-col-3">
    <span [ngClass]="{ 'red-text': baseTitle.invalid }"><strong>Title</strong></span><br /><br />
    <span *ngIf="baseTitle.invalid" class="red-text">Value Required.</span>
  </div>
  <div class="ui-grid-col-8 ui-fluid">
    <input id="title" type="text" pInputText [(ngModel)]="title" required #baseTitle="ngModel" (change)="getData()"
    (blur)="!id ? createSlug($event.target.value) : null" [ngClass]="{ 'red-borders': baseTitle.invalid }" />
  </div>
</div>

<br />