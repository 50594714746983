import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppDataService } from '../services/app-data.service';

import { UserModel } from '../models/user.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  onError = new Subject<Error>();

  constructor(protected router: Router, protected httpClient: HttpClient, protected appData: AppDataService) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(this.appData.getAppData('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.httpClient
      .post<any>('auth/custom-login', { username, password })
      .pipe(
        map(user => {
          if (user && user.id) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('token', user.id);
            this.appData.init();
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  loginFromCode(code: string) {
    localStorage.setItem('code', code);
    const params = new HttpParams().set('code', code);
    return this.httpClient
      .get<any>('auth/cognito-return', { params: params })
      .pipe(
        map(user => {
          if (user && user.id) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('token', user.id);
            this.appData.init();
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  logout() {
    this.appData.emptyAppData();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('locale');
    localStorage.removeItem('cmsLanguage');
    localStorage.removeItem('routerData');
    localStorage.removeItem('code');
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
    return;
  }
}
