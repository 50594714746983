import { Injectable, SecurityContext } from '@angular/core';

import { Message, MessageService } from 'primeng/api';
import { saveAs } from 'file-saver';

import { AppDataService } from './app-data.service';
import { MailTemplateService } from './mail-templates/mail-template.service';
import { ContentManagementApi } from '../apis/content-management-api.service';

@Injectable()
export class JsonContentService {
  private importErrorMessage: Message;
  private importSuccessMessage: Message;
  private exportErrorMessage: (title: string) => Message;
  private exportSuccessMessage: Message;
  
  constructor(
    protected messageService: MessageService,
    protected mailTemplateService: MailTemplateService,
    protected appData: AppDataService,
    protected contentManagement: ContentManagementApi
  ) {
    this.importErrorMessage = {
      severity: 'error',
      summary: 'Error importing JSON file',
      detail: `JSON file not imported`
    };
    this.importSuccessMessage = {
      severity: 'success',
      summary: 'JSON file imported successfully',
    };
    this.exportErrorMessage = (title: string) => ({
      severity: 'error',
      summary: 'Error exporting JSON file',
      detail: `JSON file not exported: ${title}`
    });
    this.exportSuccessMessage = {
      severity: 'success',
      summary: 'JSON file exported successfully',
    };
  }

  import(file: File) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsText(file, 'UTF-8');
      fileReader.onload = async () => {
        await this.parseAndSave(fileReader.result);
        resolve(null);
      };
      fileReader.onerror = () => {
        this.messageService.add(this.importErrorMessage);
        reject();
      }
    })
  }

  private getContentEndpoint(): string {
    const data = this.appData.getAppData('routerData');
    const endpoint = data[0];

    return endpoint;
  }

  private async parseAndSave(textData: string | ArrayBuffer) {
    const endpoint = this.getContentEndpoint();

    try {
      const data = JSON.parse(textData as string);

      const { title, locale, content, roles, slug } = data;
      const newContent = { title, locale, content, roles, slug: `${slug}-${new Date().getTime()}` };

      await this.mailTemplateService.createContent(endpoint, newContent);
      this.messageService.add(this.importSuccessMessage);
    } catch (error) {
      this.messageService.add(this.importErrorMessage);
    }
  }

  private download(data) {
    try {
      const { title, locale, content, roles, slug } = data;
      const textData = JSON.stringify({ title, locale, content, roles, slug }, null, 2);

      const blobData = new Blob([textData], { type: 'text/json'});
      saveAs(blobData, `${title}.json`);

      this.messageService.add(this.exportSuccessMessage);
    } catch (_) {
      this.messageService.add(this.exportErrorMessage(data && data.title));
    }
  }

  export(content) {
    this.download(content);
  }

  async exportByFilter(filters, endpoint) {
    const zipBlob = await this.contentManagement.exportContent(endpoint, filters).toPromise();
    saveAs(zipBlob, `${endpoint}.zip`);
  }
}
