<p-toast position="top-right"></p-toast>

<router-outlet>
  <ng-http-loader
    [debounceDelay]="0"
    [minDuration]="1000"
    [extraDuration]="250"
    [entryComponent]="customLoader"
  ></ng-http-loader>
</router-outlet>
