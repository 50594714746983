<p-dialog
  dismissableMask="true"
  blockScroll="true"
  [draggable]="false"
  [header]="'Reject notes'"
  [(visible)]="displayRejectModal"
  [modal]="true"
  appendTo="body"
  (onHide)="onHide()"
  key="reject"
>
  <div class="ui-g reject-modal">
    <div class="col-12">
      <label>Reject notes</label>
    </div>
    <div class="grid">
      <textarea
        pInputTextarea
        [(ngModel)]="rejectNotes"
        [rows]="5"
        [cols]="60"
        pInputTextarea
        placeholder="Reject notes"
      ></textarea>
    </div>
    <div class="col-12">
      <button
        [disabled]="!rejectNotes"
        pButton
        [label]="'common.actions.reject' | translate"
        class="btn-primary pull-right"
        (click)="onRejectDEM()"
      ></button>
    </div>
  </div>
</p-dialog>
