<section class="sectionWrapper">
    <div class="imageContainer">
        <img src="https://medias.cms.fincantieri.it/45c36639-1916-4032-b905-fa620bed85c0.png" alt="">
    </div>
    <div class="textContainer">
        <p>"L'osservatorio istituzionale di Fincantieri" è il podcast che racconta la settimana legislativa e le
            principali notizie da Governo e Parlamento.
            Analisi politica, Ministeri, Senato della Repubblica, Camera dei Deputati, Unione Europea, Autorità,
            Gazzetta Ufficiale.
            Disponibile tutti i lunedì.
        </p>
    </div>
    <div class="iframeContainer">
        <iframe
            src="https://widget.spreaker.com/player?show_key=Yt5RRw1wkDrQ&theme=light&playlist=show&playlist-continuous=false&chapters-image=true&episode_image_position=right&hide-logo=false&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=true"
            width="100%" height="100%" frameborder="0"></iframe>
    </div>
</section>