import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataStampaFeedApi {
  constructor(protected httpClient: HttpClient) {}

  getFeedFromDataStampaServerAndPersist() {
    return this.httpClient.post('network-content/data-stampa-feed/ds-feed',{});
  }
}
