import { Injectable } from '@angular/core';
import { AdnkNewsletterContent, AdnkSections, Content } from '../../models/adnk-content.model';
const env = require('src/environments/environment');

@Injectable()
export class AdnkGeneratorService {





  rebuildOriginalObject(headerContent: AdnkSections) {
    let obj = {
      newsletter: {
        titolo : "L'Osservatorio Istituzionale",
        sottotitolo : "La settimana legislativa e le principali notizie da Governo e Parlamento",
        sezioni : {
          header: {
            contenuto: []
          },
          podcast: {
            contenuto: []
          },
          parlamento: {
            contenuto: []
          },
          governo: {
            contenuto: []
          },
          eventi: {
            contenuto: []
          }
        }
   
      }
    };
    headerContent.adnkSections.forEach(element => {
      if (element.section === 'header') {
        obj.newsletter.sezioni.header.contenuto.push({
          titolo: element.title,
          testo: element.text
        });
      }
      if (element.section === 'podcast') {
        obj.newsletter.sezioni.podcast.contenuto.push({
          titolo: element.title,
          testo: element.text
        });
      }
      if (element.section === 'parlamento') {
        obj.newsletter.sezioni.parlamento.contenuto.push({
          titolo: element.title,
          testo: element.text
        });
      }
      if (element.section === 'governo') {
        obj.newsletter.sezioni.governo.contenuto.push({
          titolo: element.title,
          testo: element.text
        });
      }
      if (element.section === 'eventi') {
        obj.newsletter.sezioni.eventi.contenuto.push({
          titolo: element.title,
          testo: element.text
        });
      }
    });

    return obj as AdnkNewsletterContent;
  }
  generateHeader(headerContent: AdnkNewsletterContent): string {
    console.log(headerContent);
    const finalString = `
        <!DOCTYPE HTML
            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
            <!-- HEAD  -->
            <head>
                <!--[if gte mso 9]>
                <xml>
                <o:OfficeDocumentSettings>
                    <o:AllowPNG/>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
                </xml>
                <![endif]-->
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <meta name="x-apple-disable-message-reformatting">
                <!--[if !mso]><!-->
                <meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
                <title></title>

                <style type="text/css">
                    @media only screen and (min-width: 620px) {
                    .u-row {
                        width: 600px !important;
                    }

                    .u-row .u-col {
                        vertical-align: top;
                    }

                    .u-row .u-col-100 {
                        width: 600px !important;
                    }

                    }

                    @media (max-width: 620px) {
                    .u-row-container {
                        max-width: 100% !important;
                        padding-left: 0px !important;
                        padding-right: 0px !important;
                    }

                    .u-row .u-col {
                        min-width: 320px !important;
                        max-width: 100% !important;
                        display: block !important;
                    }

                    .u-row {
                        width: 100% !important;
                    }

                    .u-col {
                        width: 100% !important;
                    }

                    .u-col>div {
                        margin: 0 auto;
                    }
                    }

                    body {
                    margin: 0;
                    padding: 0;
                    }

                    table,
                    tr,
                    td {
                    vertical-align: top;
                    border-collapse: collapse;
                    }

                    p {
                    margin: 0;
                    }

                    .ie-container table,
                    .mso-container table {
                    table-layout: fixed;
                    }

                    * {
                    line-height: inherit;
                    }

                    a[x-apple-data-detectors='true'] {
                    color: inherit !important;
                    text-decoration: none !important;
                    }

                    table,
                    td {
                    color: #111111;
                    }

                    #u_body a {
                    color: #144cb2;
                    text-decoration: none;
                    }

                    @media (max-width: 480px) {
                    #u_content_text_21 .v-font-size {
                        font-size: 16px !important;
                    }
                    }
                </style>
            <!--[if !mso]><!-->
            <link href="https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet" type="text/css"><!--<![endif]-->
        </head>

        <body class="clean-body u_body"
        style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #ffffff;color: #111111">
        <!--[if IE]><div class="ie-container"><![endif]-->
        <!--[if mso]><div class="mso-container"><![endif]-->
        <table id="u_body"
            style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #ffffff;width:100%"
            cellpadding="0" cellspacing="0">
            <tbody>
            <tr style="vertical-align: top">
                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #ffffff;"><![endif]-->


                <div class="u-row-container" style="padding: 0px;background-color: #ffffff">
                    <div class="u-row"
                    style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                    <div
                        style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: #ffffff;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #ffffff;"><![endif]-->

                        <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 10px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                        <div class="u-col u-col-100"
                        style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                        <div style="height: 100%;width: 100% !important;">
                            <!--[if (!mso)&(!IE)]><!-->
                            <div
                            style="box-sizing: border-box; height: 100%; padding: 10px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                            <!--<![endif]-->

                            <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                                width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Arial', sans-serif;"
                                    align="left">

                                    <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                        style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #f7f7f7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                        <tbody>
                                        <tr style="vertical-align: top">
                                            <td
                                            style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                            <span>&#160;</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <!--[if (!mso)&(!IE)]><!-->
                            </div><!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td><![endif]-->
                        <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                    </div>
                    </div>
                </div>


                <!-- IMMAGINE HEADER  -->
                <div class="u-row-container" style="padding: 0px;background-color: #ffffff">
                    <div class="u-row"
                    style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                    <div
                        style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: #ffffff;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #f7f7f7;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                        <div class="u-col u-col-100"
                        style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                        <div style="background-color: #f7f7f7;height: 100%;width: 100% !important;">
                            <!--[if (!mso)&(!IE)]><!-->
                            <div
                            style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                            <!--<![endif]-->

                            <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                                width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:'Arial', sans-serif;"
                                    align="left">

                                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tr>
                                        <td style="padding-right: 0px;padding-left: 0px;" align="center">

                                            <img align="center" border="0"
                                            src="https://medias.cms.fincantieri.it/45c36639-1916-4032-b905-fa620bed85c0.png"
                                            alt="" title=""
                                            style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 600px;"
                                            width="600" />

                                        </td>
                                        </tr>
                                    </table>

                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <!--[if (!mso)&(!IE)]><!-->
                            </div><!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td><![endif]-->
                        <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                    </div>
                    </div>
                </div>

                <div class="u-row-container" style="padding: 0px;background-color: transparent">
                    <div class="u-row"
                    style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                    <div
                        style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #f7f7f7;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                        <div class="u-col u-col-100"
                        style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                        <div
                            style="background-color: #f7f7f7;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                            <!--[if (!mso)&(!IE)]><!-->
                            <div
                            style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                            <!--<![endif]-->

                            <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                                width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Arial', sans-serif;"
                                    align="left">

                                    <div class="v-font-size"
                                        style="font-family: 'Lato',sans-serif; font-size: 16px; color: #111111; line-height: 140%; text-align: left; word-wrap: break-word;">
                                        <p style="line-height: 140%;"><em>${headerContent.newsletter.sottotitolo}</em></p>
                                    </div>

                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <!--[if (!mso)&(!IE)]><!-->
                            </div><!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td><![endif]-->
                        <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                    </div>
                    </div>
                </div>

        
        `;

    return finalString;
  }

  generatePodcastSection(): string {
    return `
    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                    <div class="u-row"
                    style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                    <div
                        style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                        <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                        <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #f7f7f7;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                        <div class="u-col u-col-100"
                        style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                        <div
                            style="background-color: #f7f7f7;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                            <!--[if (!mso)&(!IE)]><!-->
                            <div
                            style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                            <!--<![endif]-->

                            <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                                width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding-top:10px;padding-bottom:10px;font-family:'Arial', sans-serif;"
                                    align="left">

                                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tr>
                                        <td style="padding-right: 0px;padding-left: 0px;" align="center">
                                            <a href="${env.environment.feBaseUrl}/podcast"
                                            target="_blank">
                                            <img align="center" border="0"
                                                src="https://medias.cms.fincantieri.it/4618d8f3-a780-4fe0-9762-9cbf0b2dc96c.png"
                                                alt="" title=""
                                                style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 600px;"
                                                width="600" />
                                            </a>
                                        </td>
                                        </tr>
                                    </table>

                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <!--[if (!mso)&(!IE)]><!-->
                            </div><!--<![endif]-->
                        </div>
                        </div>
                        <!--[if (mso)|(IE)]></td><![endif]-->
                        <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                    </div>
                    </div>
                </div>
    
    `;
  }

  generateSections(sectionContent: Content[], sectionName: string): string {
    let finalString = '';

    const fixedContent =  `
            
          <div class="u-row-container" style="padding: 0px;background-color: transparent">
            <div class="u-row"
              style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
              <div
                style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #f7f7f7;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                <div class="u-col u-col-100"
                  style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                  <div
                    style="background-color: #f7f7f7;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <!--<![endif]-->

                    <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                                width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:'Arial', sans-serif;"
                                    align="left">

                                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tr>
                                        <td style="padding-right: 0px;padding-left: 0px;" align="center">

                                            <img align="center" border="0"
                                            src="${this.imageMap[sectionName]}"
                                            alt="" title=""
                                            style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 600px;"
                                            width="600" />

                                        </td>
                                        </tr>
                                    </table>

                                    </td>
                                </tr>
                                </tbody>
                            </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div><!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
        `;

    let sectionFinalString = '';

    sectionContent.forEach(item => {
      let itemString = `
            
                <div class="u-row-container" style="padding: 0px;background-color: transparent">
            <div class="u-row"
              style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
              <div
                style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: transparent;"><![endif]-->

                <!--[if (mso)|(IE)]><td align="center" width="600" style="background-color: #f7f7f7;width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                <div class="u-col u-col-100"
                  style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                  <div
                    style="background-color: #f7f7f7;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                    <!--[if (!mso)&(!IE)]><!-->
                    <div
                      style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <!--<![endif]-->

                      <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                        width="100%" border="0">
                        <tbody>
                          <tr>
                            <td
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Arial', sans-serif;"
                              align="left">

                              <!--[if mso]><table width="100%"><tr><td><![endif]-->
                              <h1 class="v-font-size"
                                style="margin: 0px; line-height: 140%; text-align: left; word-wrap: break-word; font-family: 'Lato',sans-serif; font-size: 18px; font-weight: 400;">
                                <span><strong><span style="line-height: 23.4px;">${item.titolo}</span></strong></span></h1>
                              <!--[if mso]></td></tr></table><![endif]-->

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                        width="100%" border="0">
                        <tbody>
                          <tr>
                            <td
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Arial', sans-serif;"
                              align="left">

                              <div class="v-font-size"
                                style="font-family: 'Lato',sans-serif; font-size: 16px; line-height: 140%; text-align: left; word-wrap: break-word;">
                                <p style="line-height: 140%;">${item.testo}</p>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table style="font-family:'Arial', sans-serif;" role="presentation" cellpadding="0" cellspacing="0"
                        width="100%" border="0">
                        <tbody>
                          <tr>
                            <td
                              style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:'Arial', sans-serif;"
                              align="left">

                              <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                                style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #BBBBBB;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                <tbody>
                                  <tr style="vertical-align: top">
                                    <td
                                      style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                      <span>&#160;</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <!--[if (!mso)&(!IE)]><!-->
                    </div><!--<![endif]-->
                  </div>
                </div>
                <!--[if (mso)|(IE)]></td><![endif]-->
                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
              </div>
            </div>
          </div>
            
            `;
      sectionFinalString += itemString;
    });

    finalString += fixedContent;
    finalString += sectionFinalString;

    return finalString;
  }

  imageMap = {
    podcast : '',
    governo : 'https://medias.cms.fincantieri.it/7a3f39bf-219f-4def-b87d-96e533bc9d5b.png',
    parlamento : 'https://medias.cms.fincantieri.it/5602e46d-00e8-40b1-886c-0fec5ae49982.png',
    eventi : 'https://medias.cms.fincantieri.it/bc45b883-8100-4331-85b4-d5379b16b660.png',
    sommario : 'https://medias.cms.fincantieri.it/a8137cf0-2793-4f71-b5fb-804356f728b9.png'
  }

  generateMail(mailTemplate, content: AdnkSections): string {

    let emailTemplate: string = '';
    const newObject = this.rebuildOriginalObject(content)
    const header: string = this.generateHeader(newObject);
    const headerContent: string = this.generateSections(newObject.newsletter.sezioni.header.contenuto, 'sommario');
    const podcastSection: string = this.generateSections(newObject.newsletter.sezioni.podcast.contenuto, 'podcast');
    const parlamentoSection: string = this.generateSections(
      newObject.newsletter.sezioni.parlamento.contenuto,
      'parlamento'
    );
    const governoSection: string = this.generateSections(newObject.newsletter.sezioni.governo.contenuto, 'governo');
    const nextWeekSection: string = this.generateSections(
      newObject.newsletter.sezioni.eventi.contenuto,
      'eventi'
    );
    const podcastBanner = this.generatePodcastSection();

    emailTemplate += header;
    emailTemplate += headerContent;
    emailTemplate += podcastBanner;
    emailTemplate += podcastSection;
    emailTemplate += parlamentoSection;
    emailTemplate += governoSection;
    emailTemplate += nextWeekSection;

    return emailTemplate;
  }
}
