import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  OnInit,
  AfterViewChecked,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { AppDataService } from '../../../core/services/app-data.service';
import { ConfirmationService, MessageService } from 'primeng/api';
const env = require('src/environments/environment');
@Component({
  selector: 'media-pdf-view',
  templateUrl: './media-pdf-view.component.html',
  styleUrls: ['./media-pdf-view.component.scss'],
})
export class MediaPdfViewComponent implements OnInit, AfterViewChecked {
  @Input() selectPdfEnabled;
  @Output() pdfClicked = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  pdfId: string;
  pdfList: any[];
  totalRecords: number;
  sortOptions: any[];
  sortOrder: number;
  sortField: string;
  filterBy: string;
  loading: boolean;
  baseUrl: string = env.environment.mediaUrl;
  visible: boolean;
  cmsLanguage: string;
  sysLanguage: any;
  containerName: string;
  tagOptions: any[];
  filterByTag: string[];
  folders: any[];
  filterByFolder: string;
  userRole: string;

  constructor(
    private mediaApi: MediaApi,
    private cdRef: ChangeDetectorRef,
    protected appData: AppDataService,
    private messageService: MessageService,
    protected translateService: TranslateService,
    protected confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.containerName = env.environment.s3Bucket;
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterBy = '';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
    this.userRole = this.appData.getAppData('currentUser').roles[0].name;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onClearFilters() {
    this.filterBy = '';
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.refresh.emit({});
    this.pdfClicked.emit(undefined);
    this.updateDataView();
  }

  updateDataView() {
    this.visible = false;
    this.pdfList = [];
    setTimeout(() => (this.visible = true), 0);
  }

  lazyLoadingPdf(event) {
    this.loading = true;

    const filterCount = [];
    const filters = [];
    // filters.push('filter[offset]=' + event.first);
    // filters.push('filter[limit]=' + (event.first + event.rows));
    filters.push('filter[where][type][regexp]=/pdf/');
    filterCount.push('where[type][regexp]=/pdf/');

    if (this.sortField !== null && this.sortOrder !== null) {
      const ordering = this.sortOrder === -1 ? ' DESC' : ' ASC';
      filters.push('filter[order]=' + this.sortField + ordering);
    }

    filters.push('filter[where][containerName]=' + this.containerName);
    filterCount.push('where[containerName]=' + this.containerName);

    filters.push('filter[where][roles][inq]=' + this.userRole);
    filterCount.push('where[roles][inq]=' + this.userRole);

    if (this.filterByFolder.length > 0 && this.filterByFolder !== 'root') {
      filters.push('filter[where][folder]=' + this.filterByFolder);
      filterCount.push('where[folder]=' + this.filterByFolder);
    }

    if (this.filterBy !== '') {
      filters.push(
        'filter[where][or][0][fileName][regexp]=/' + this.filterBy + '/i'
      );
      filters.push(
        'filter[where][or][1][title][regexp]=/' + this.filterBy + '/i'
      );
      filters.push(
        'filter[where][or][2][description][regexp]=/' + this.filterBy + '/i'
      );
      filters.push(
        'filter[where][or][3][folder][regexp]=/' + this.filterBy + '/i'
      );
      filters.push(
        'filter[where][or][4][originalFileName][regexp]=/' +
          this.filterBy +
          '/i'
      );
      filterCount.push(
        'where[or][0][fileName][regexp]=/' + this.filterBy + '/i'
      );
      filterCount.push('where[or][1][title][regexp]=/' + this.filterBy + '/i');
      filterCount.push(
        'where[or][2][description][regexp]=/' + this.filterBy + '/i'
      );
      filterCount.push('where[or][3][folder][regexp]=/' + this.filterBy + '/i');
      filterCount.push(
        'where[or][4][originalFileName][regexp]=/' + this.filterBy + '/i'
      );
    }

    if (this.filterByTag.length > 0) {
      this.filterByTag.forEach(tag => {
        filters.push(`filter[where][tag][inq]=${tag}`);
        filterCount.push(`where[tag][inq]=${tag}`);
      });
    }

    // this.mediaApi.getImagesCount(filterCount).subscribe((res: any) => {
    //   this.totalRecords = res.count;
    // });

    this.mediaApi.getPdf(filters).subscribe((res: any) => {
      //this.pdfList = res;
      //this.pdfList.forEach((pdf, i) => {
      //  this.pdfList[i].src = this.baseUrl + pdf.fileName + this.apiKey;
      //});
      this.pdfList = this.filterByFolder
        ? res.map(this.mapPdfInFolder)
        : this.mapPdfFolder(res);
      this.totalRecords = this.pdfList.length;
      this.pdfList = this.pdfList.slice(event.first);
      this.loading = false;
    });
  }

  mapPdfInFolder = (pdf, i) => {
    return { ...pdf, src: this.baseUrl + pdf.fileName };
  };

  mapPdfFolder = res => {
    const images = {};
    res.forEach(curr => {
      const key = curr.folder ? curr.folder : 'root';
      const currFold = images[key] ? images[key] : [];
      currFold.push({ folder: key });
      images[key] = currFold;
    });
    const folders = Object.keys(images).map(curr => {
      return { folder: curr, tot: images[curr].length };
    });
    return folders.sort((a, b) => {
      return a.folder > b.folder ? 1 : a.folder < b.folder ? -1 : 0;
    });
  };

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  open(folder) {
    this.filterByFolder = folder;
    this.updateDataView();
  }

  onPdfClick(pdfData) {
    this.pdfClicked.emit(pdfData);
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe((language: any) => {
      this.sysLanguage = language;
      this.sortOptions = [
        { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
        { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
        { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
        { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
      ];
      this.visible = true;
    }); */
    this.sortOptions = [
      {
        label: this.translateService.instant('common.filters.newestFirst'),
        value: '!updatedAt',
      },
      {
        label: this.translateService.instant('common.filters.oldestFirst'),
        value: 'updatedAt',
      },
      {
        label: this.translateService.instant('common.filters.filenameAZ'),
        value: 'fileName',
      },
      {
        label: this.translateService.instant('common.filters.filenameZA'),
        value: '!fileName',
      },
    ];
    this.visible = true;
  }

  showConfirm(pdfId) {
    this.pdfId = pdfId;
    this.confirmationService.confirm({
      key: 'confirm',
      header: 'Delete',
      icon: 'fa fa-exclamation-triangle',
      message: 'Are you sure?\nConfirm to proceed.',
      accept: this.onConfirm.bind(this),
    });
  }

  onConfirm() {
    this.mediaApi.deleteMedia(this.pdfId).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Delete Completed',
      });
      setTimeout(() => {
        this.updateDataView();
      }, 1000);
    });
  }

  onReject() {
    this.pdfId = undefined;
    this.messageService.clear('g');
  }
}
