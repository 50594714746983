import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RouteDataService {
  private endpoint = new BehaviorSubject<string>('');
  private type = new BehaviorSubject<string>('');
  currentEndpoint = this.endpoint.asObservable();
  currentType = this.type.asObservable();

  constructor() {}

  changeData(endpoint: string, type: string) {
    this.endpoint.next(endpoint);
    this.type.next(type);
  }
}
