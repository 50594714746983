<div class="grid">
  <div class="col-12">
    <media-upload
      (uploadCompleted)="updateDataView()"
      [allFolder]="imagesList"
      [currentFolder]="filterByFolder"
    >
    </media-upload>
  </div>
  <div class="col-12">
    <p-dataView
      *ngIf="visible"
      #dv
      [value]="imagesList"
      layout="grid"
      filterBy="title,alt"
      [lazy]="true"
      (onLazyLoad)="lazyLoadingImages($event)"
      [paginator]="true"
      [rows]="12"
      [totalRecords]="totalRecords"
      [loading]="loading"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
    >
      <p-header>
        <div class="ui-helper-clearfix">
          <div class="grid justify-content-between">
            <div class="sm:col-12 md:col-3">
              <input
                type="text"
                pInputText
                [placeholder]="'common.actions.search' | translate | titlecase"
                [(ngModel)]="filterBy"
              />
            </div>
            <div class="sm:col-12 md:col-3">
              <p-multiSelect
                [options]="tagOptions"
                [defaultLabel]="
                  'common.actions.typeOfContent' | translate | titlecase
                "
                [(ngModel)]="filterByTag"
                [filter]="false"
                [maxSelectedLabels]="2"
                [selectedItemsLabel]="'{0} items selected'"
                styleClass="multiselect-mobileregulation-width"
              ></p-multiSelect>
            </div>
            <div class="sm:col-12 md:col-3">
              <p-dropdown
                [pTooltip]="'media.sortHint' | translate | titlecase"
                tooltipPosition="top"
                [options]="sortOptions"
                [placeholder]="'common.actions.sortBy' | translate | titlecase"
                (onChange)="onSortChange($event)"
              ></p-dropdown>
            </div>
            <div class="sm:col-12 md:col-3 flex justify-content-end">
              <button
                pButton
                type="button"
                [label]="'common.actions.search' | translate | titlecase"
                icon="fa fa-search"
                (click)="updateDataView()"
              ></button>
            </div>
          </div>
          <div class="grid">
            <div class="sm:col-12 md:col-6">
              <span
                *ngIf="filterByFolder.length > 0"
                style="font-size: x-large;"
              >
                <i class="fa fa-folder-open" aria-hidden="true"></i
                ><span style="margin: 10px;">{{ filterByFolder }}</span>
              </span>
            </div>
            <div class="sm:col-12 md:col-6 flex justify-content-end">
              <button
                pButton
                type="button"
                icon="fa {{
                  filterByFolder.length > 0 ? 'fa-level-up-alt' : 'fa-sync'
                }}"
                [label]="
                  (filterByFolder.length > 0
                    ? 'common.actions.moveFolderUp'
                    : 'common.actions.clearFilters'
                  )
                    | translate
                    | titlecase
                "
                class="p-button-secondary"
                (click)="onClearFilters()"
              ></button>
            </div>
          </div>
        </div>
      </p-header>

      <ng-template let-image pTemplate="gridItem">
        <div
          *ngIf="filterByFolder.length > 0"
          id="{{ image.id }}"
          (click)="onImageClick(image.id)"
          style="padding: .5em; border-radius: 5px; cursor: pointer"
          class="sm:col-12 md:col-4"
          [style.border]="isSelected(image.id)"
        >
          <p-panel
            [header]="
              image.title
                ? image.title
                : image.originalFileName
                ? image.originalFileName
                : image.fileName
            "
            [style]="{
              'font-size': '.8em',
              'text-align': 'center',
              'word-break': 'break-all'
            }"
          >
            <img
              src="{{ image.src }}"
              style="max-width: 100%; height: auto;"
              class="responsive"
            />
            <hr class="ui-widget-content" style="border-top:0" />
            <div class="image-detail">
              <div *ngIf="image.title">
                <b>{{ 'common.labels.title' | translate | titlecase }} </b>
                <i>{{ image.title }}</i>
              </div>
              <div *ngIf="image.description">
                <b
                  >{{ 'common.labels.description' | translate | titlecase }}
                </b>
                <i>{{ image.description }}</i>
              </div>
              <div>
                <b>{{ 'common.labels.published' | translate | titlecase }} </b>
                <i>{{image.createdAt | date: 'dd/MM/y hh:mm a'}}</i>
              </div>
              <div>
                <b
                  >{{ 'common.labels.lastModified' | translate | titlecase }}
                </b>
                <i>{{image.updatedAt | date: 'dd/MM/y hh:mm a'}}</i>
              </div>
            </div>
          </p-panel>
        </div>

        <div
          *ngIf="filterByFolder.length === 0"
          id="{{ image.id }}"
          style="padding: .5em; border-radius: 5px; "
          class="sm:col-12 md:col-4"
          [style.border]="isSelected(image.id)"
        >
          <p-panel
            [header]="image.folder"
            [style]="{
              'font-size': '.8em',
              'text-align': 'center',
              'word-break': 'break-all'
            }"
          >
            <div class="grid">
              <div class="col-12">
                <i
                  class="fa fa-folder"
                  style="font-size: 15em;"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="col-12">
                <button
                  pButton
                  type="button"
                  icon="fa fa-folder-open"
                  [label]="'common.actions.open' | translate | titlecase"
                  class="p-button-secondary flex-1"
                  (click)="onImageDoubleClick(image)"
                ></button>
              </div>
            </div>
          </p-panel>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>
