import { TranslateService } from '@ngx-translate/core';
import { CommonApi } from './../../../core/apis/common.api';
import { AuthService } from './../../../core/services/auth.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnDestroy {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordOK: boolean;
  isButtonDisabled = true;
  isPasswordConfirmed = true;
  pwRegEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*]).{7,}$');

  // ---

  subscriptions: Subscription = new Subscription();

  // ---

  constructor(
    protected messageService: MessageService,
    protected authService: AuthService,
    protected commonApi: CommonApi,
    protected translateService: TranslateService
  ) {}

  // ---

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // ---

  checkOldPassword = () => {
    return (
      this.oldPassword !== '' &&
      this.newPassword !== '' &&
      this.oldPassword !== undefined &&
      this.newPassword !== undefined &&
      this.newPassword === this.oldPassword
    );
  };

  checkNewPassword = () => {
    return (
      this.passwordOK &&
      this.confirmPassword !== '' &&
      this.newPassword !== '' &&
      this.confirmPassword !== undefined &&
      this.newPassword !== undefined &&
      this.confirmPassword === this.newPassword
    );
  };

  checkPasswordFormat = (event: any) => {
    this.passwordOK = this.pwRegEx.test(this.newPassword);
  };

  updatePassword = () => {
    this.subscriptions.add(
      this.authService.currentUser.subscribe((user: any) => {
        const data = {
          id: user.userId,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        };
        this.commonApi.updatePassword(data).subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('common.messages.success.title'),
              detail: this.translateService.instant('common.messages.success.updateText')
            });
            this.authService.logout();
            location.reload();
          },
          error => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('common.messages.error.title'),
              detail: this.translateService.instant('common.messages.error.updateText')
            });
          }
        );
      })
    );
  };

  // ---

  isButtonEnabled = () => {
    if (this.checkNewPassword()) {
      return true;
    }
    return false;
  };
}
