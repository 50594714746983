import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AdnkronosFeedApi {
  constructor(protected httpClient: HttpClient) {}

  getFeedFromAdnkServerAndPersist() {
    return this.httpClient.post('network-content/adnk-feed/adnk-feed',{});
  }
}
