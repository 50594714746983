import { Injectable } from '@angular/core';

import { MessageService } from 'primeng/api';
import { lastValueFrom, Subject } from 'rxjs';
import io from 'socket.io-client';

import { environment as env } from 'src/environments/environment';
import { NotificationsApi } from '../apis/notifications.api';

@Injectable()
export class NotificationService {
  socket: any;
  count: Subject<number> = new Subject<number>();
  notificationUpdate = new Subject();
  constructor(protected messageService: MessageService, protected notificationsApi: NotificationsApi) {}

  async getNotificationsCount() {
    try {
      const notifications = await lastValueFrom(
        this.notificationsApi.getUnreadNotificationsCount()
      );
    this.count.next(notifications.count);
    } catch (error) {}
  }

  init(token) {
    try {
      this.socket = io(env.socketUrl, {
        auth: {
          token
        },
        path: env.socketPath
      });
      this.listenToMessages();
    } catch (error) {
      console.error(error);
    }
  }

  listenToMessages() {
    this.socket.on('notificationCount', async res => {
      const { count, notificationUpdate } = res;
      this.count.next(count);
      this.notificationUpdate.next(notificationUpdate); //TODO to review
    });

    this.socket.on('notification', async notification => {
      this.count.next(notification.count);
      this.notificationUpdate.next(notification.notificationUpdate); //TODO to review
      this.messageService.add({
        severity: notification.severity,
        summary: notification.summary,
        detail: notification.detail
      });
    });
  }
  markAllAsRead() {
    return this.updateCount('markAllAsRead', null);
  }
  readNotification(id: any) {
    return this.updateCount('readNotification', id);
  }
  unreadNotification(id: any) {
    return this.updateCount('unReadNotification', id);
  }

  hideNotification(id: any) {
    return this.updateCount('hideNotification', id);
  }

  updateCount(msgTitle, id) {
    return new Promise((resolve, reject) => {
      this.socket.emit(msgTitle, { _id: id }, res => {
        const { count, error } = res;
        if (error) return reject(error);
        else {
          resolve(count);
        }
      });
    });
  }
}
