<div class="grid">
  <div class="col-12">
    <button pButton type="button" icon="fa fa-upload" [label]="'common.actions.upload' | translate | titlecase"
      class="btn-info" (click)="showMeUploadArea()"></button>
  </div>
  <div class="col-12" *ngIf="showUploadArea">
    <div class="col-3" *ngIf="!currentFolder">
      <h6>Seleziona tipo cartella</h6>
      <p-radioButton name="group1" label="Nuova cartella" value="folderNew" [(ngModel)]="chooseTypeFolder"
        (onClick)="onClick('folderNew')" inputId="opt1">
      </p-radioButton>
      <br />
      <p-radioButton *ngIf="optionsFolder.length > 1 || (optionsFolder[0] && optionsFolder[0].value !== 'root')"
        name="group1" label="Cartella preesistente" value="folderOld" [(ngModel)]="chooseTypeFolder"
        (onClick)="onClick('folderOld')" inputId="opt2">
      </p-radioButton>
    </div>
    <div class="col-3" *ngIf="chooseTypeFolder && chooseTypeFolder === 'folderNew'">
      <h6>Nome cartella</h6>
      <input type="text" [(ngModel)]="newFolder" (input)="checkFolder()">
      <p-message *ngIf="newFolderInvalid" severity="error" [text]="'common.labels.folderAlreadyExists' | translate">
      </p-message>
    </div>
    <div class="col-3" *ngIf="!currentFolder && chooseTypeFolder && chooseTypeFolder === 'folderOld'">
      <h6>Seleziona cartella</h6>
      <p-dropdown [options]="optionsFolder" [(ngModel)]="selectedFolder">
      </p-dropdown>
    </div>
    <div class="col-3"
      *ngIf="visible && chooseTypeFolder && ((chooseTypeFolder === 'folderNew' && !newFolderInvalid && newFolder.length > 0 ) || (chooseTypeFolder === 'folderOld' && selectedFolder))">
      <h6>Condividi immagine con</h6>
      <p-multiSelect [options]="roleOptions" [defaultLabel]="'common.labels.role' | translate | titlecase"
        [(ngModel)]="selectedRoles" [filter]="false" [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} items selected'"
        styleClass="multiselect-mobileregulation-width" [showToggleAll]="false"></p-multiSelect>
    </div>
    <div class="col-3"
      *ngIf="visible && chooseTypeFolder && ((chooseTypeFolder === 'folderNew' && !newFolderInvalid && newFolder.length > 0 ) || (chooseTypeFolder === 'folderOld' && selectedFolder))">
      <h6>Clicca per selezionare il file</h6>
      <p-fileUpload #upload *ngIf="visible" mode="basic" multiple="true" customUpload="true"
        (uploadHandler)="onUpload($event, upload)" [chooseLabel]="'common.actions.upload' | translate | titlecase"
        accept="video/mp4,video/mpeg,video/quicktime" maxFileSize="2147483648" auto="auto"></p-fileUpload>
    </div>
    <div class="col-12" *ngIf="uploadProgress && uploadProgress.length">
      <div *ngFor="let prog of uploadProgress">{{prog}}</div>
    </div>
  </div>
</div>
