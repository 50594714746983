import { Subscription } from 'rxjs';
import { AuthService } from './../../../core/services/auth.service';
import { ScrollPanel } from 'primeng/primeng';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { UserModel } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';

enum MenuOrientation {
  STATIC,
  OVERLAY,
  SLIM,
  HORIZONTAL
}

@Component({
  templateUrl: './back-office-main.component.html',
  styleUrls: ['./back-office-main.component.scss']
})
export class BackOfficeMainComponent implements OnDestroy {
  currentUser: UserModel;
  layoutMode: MenuOrientation = MenuOrientation.STATIC;
  userID: string;
  rotateMenuButton: boolean;
  topbarMenuActive: boolean;
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  staticMenuMobileActive: boolean;
  menuClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: any;
  resetMenu: boolean;
  menuHoverActive: boolean;
  profileMode = 'inline';

  // ---

  subscriptions: Subscription = new Subscription();

  // ---

  @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

  // ---

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected notificationService: NotificationService
  ) {
    this.subscriptions.add(
      this.authService.currentUser.subscribe(
        (user: any) => {
          if (user === null) {
            this.router.navigateByUrl('login');
            return;
          }
          this.userID = user.userId;
          this.currentUser = user;

          this.notificationService.init(user.id);
          // Init notification service (ie socket) passing the auth token
        },
        error1 => {
          console.log(error1);
          this.router.navigateByUrl('login');
        }
      )
    );
  }

  // ---

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // ---

  onLayoutClick = () => {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }
    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }
      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }
      this.menuHoverActive = false;
    }
    this.topbarItemClick = false;
    this.menuClick = false;
  };

  onMenuButtonClick = event => {
    this.menuClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;
    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }
    event.preventDefault();
  };

  onMenuClick = $event => {
    this.menuClick = true;
    this.resetMenu = false;
    if (!this.isHorizontal()) {
      setTimeout(() => {
        this.layoutMenuScrollerViewChild.moveBar();
      }, 450);
    }
  };

  onTopbarMenuButtonClick = event => {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    this.hideOverlayMenu();
    event.preventDefault();
  };

  onTopbarSubItemClick = event => {
    event.preventDefault();
  };

  // ---

  hideOverlayMenu = () => {
    this.rotateMenuButton = false;
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  };

  // ---

  isTablet = () => {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  };

  isDesktop = () => {
    return window.innerWidth > 1024;
  };

  isMobile = () => {
    return window.innerWidth <= 640;
  };

  isOverlay = () => {
    return this.layoutMode === MenuOrientation.OVERLAY;
  };

  isHorizontal = () => {
    return this.layoutMode === MenuOrientation.HORIZONTAL;
  };

  isSlim = () => {
    return this.layoutMode === MenuOrientation.SLIM;
  };
}
