import { Injectable } from '@angular/core';
import { AppDataService } from './app-data.service';
import { MenuService } from './menu.service';

@Injectable()
export class InitService {
  constructor(
    protected menuService: MenuService,
    protected appData: AppDataService
  ) {}
  async init() {
    this.appData.init();
    if (this.appData.getAppData('currentUser')) {
      this.menuService.getMenu();
    }
  }
}
