import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnChanges {
  @Input() displayAddressModal: boolean;
  @Output() displayAddressModalChange = new EventEmitter<boolean>();
  @Input() toList;

  formattedToList: Array<string>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toList && !!changes.toList.currentValue) {
      this.formattedToList = Object.values(this.toList).reduce(
        (accumulator: Array<string>, current: Array<{ email: string }>)  : Array<string> => ([
          ...accumulator,
          ...current.map(({ email }) => email)
        ]),
        []
      ) as Array<string>;
    }
  }

  onHide () {
    this.displayAddressModalChange.emit();
  }


}
