<p-panel *ngIf="visible" header="Media Panel">
  <div class="ui-g ui-fluid">
    <div class="col-12" style="border: 1px solid lightgray; display: flex; place-items: center; flex-direction: column;">
      <label style="word-wrap: break-word;">{{ imageName }}</label>
      <img *ngIf="isImage && imageSrc" src="{{ imageSrc }}" class="img-responsive" width="200" />
      <div *ngIf="imageSrc"style="text-align: center;"><a target="_blank" href="{{ imageSrc.split('?')[0] }}">view original size</a></div>
    </div>
    <!-- <div class="col-12">
      <p-checkbox [(ngModel)]="private" binary="true" [label]="'common.labels.private' | translate | titlecase"
        [disabled]="!imageId"></p-checkbox>
    </div> -->
    <div class="col-12">
      <label>Image ID</label><br />
      <input id="imageId" type="text" pInputText [(ngModel)]="imageId" [disabled]="true" />
    </div>
    <div class="col-12">
      <label>{{ 'common.labels.language' | translate | titlecase }}</label><br />
      <p-dropdown [options]="languagesOptions" [(ngModel)]="selectedLanguage"
        (onChange)="onLanguageChange($event)" [disabled]="!imageId"></p-dropdown>
    </div>
    <div class="col-12">
      <label>{{ 'common.labels.title' | translate | titlecase }}</label><br />
      <input id="title" type="text" pInputText [(ngModel)]="title" [disabled]="!imageId" />
    </div>
    <div class="col-12">
      <label>{{ 'common.labels.description' | translate | titlecase }}</label><br />
      <textarea id="description" [rows]="3" [cols]="30" pInputTextarea [(ngModel)]="description"
        [disabled]="!imageId"></textarea>
    </div>
    <div class="col-12">
      <label>{{ 'common.labels.tag' | translate | titlecase }}</label><br />
      <!-- <p-dropdown [options]="tagsOptions" [(ngModel)]="selectedTag" [disabled]="!imageId">
      </p-dropdown> -->
      <p-multiSelect [options]="tagsOptions" [defaultLabel]="'common.actions.typeOfContent' | translate | titlecase"
        [(ngModel)]="selectedTag" [filter]="false" [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} items selected'"
        styleClass="multiselect-mobileregulation-width" [disabled]="!imageId"></p-multiSelect>
    </div>
    <div class="col-12">
      <label>Condivisa con</label>
      <p-multiSelect [options]="roleOptions" [defaultLabel]="'common.labels.role' | translate | titlecase"
        [(ngModel)]="selectedRoles" [filter]="false" [maxSelectedLabels]="2" [selectedItemsLabel]="'{0} items selected'"
        styleClass="multiselect-mobileregulation-width" [showToggleAll]="false" [disabled]="!imageId"></p-multiSelect>
    </div>
  </div>
  <hr />
  <div class="grid">
    <div class="col-12">
      <button [disabled]="!imageId" pButton type="button" icon="fa fa-save"
        [label]="'common.actions.save' | translate | titlecase" class="btn-info" (click)="onSave()"></button>
      <button [disabled]="!imageId" pButton type="button" icon="fa fa-trash"
        [label]="'common.actions.delete' | translate | titlecase" class="btn-info" (click)="showConfirm()"></button>
    </div>
  </div>
</p-panel>

<!-- <p-toast position="center" key="e" (onClose)="onReject()" [modal]="true" [baseZIndex]="5000">
 //TODO modal remove -->
<p-toast position="center" key="e" (onClose)="onReject()" [baseZIndex]="5000">

  <ng-template let-message pTemplate="message">
    <div style="text-align: center">
      <i class="pi pi-exclamation-triangle" style="font-size: 3em; color:grey"></i>
      <h3>{{ message.summary }}</h3>
      <p>{{ message.detail }}</p>
    </div>
    <div class="ui-g ui-fluid">
      <div class="col-6">
        <button type="button" pButton (click)="onConfirm()" label="Yes" class="btn-info"></button>
      </div>
      <div class="col-6">
        <button type="button" pButton (click)="onReject()" label="No" class="btn-primary"></button>
      </div>
    </div>
  </ng-template>
</p-toast>
