import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppDataService } from 'src/app/core/services/app-data.service';
import { InputOptionService } from 'src/app/core/services/input-option.service';

@Component({
  selector: 'system-language',
  templateUrl: './system-language.component.html',
  styleUrls: ['./system-language.component.scss']
})
export class SystemLanguageComponent implements OnInit {
  languagesOptions: any;
  selectedLanguage: any;
  placeholder: any;

  // ---

  @Output() languageValues = new EventEmitter();

  // ---

  constructor(
    protected inputOptionService: InputOptionService,
    protected appData: AppDataService,
    protected translateService: TranslateService
  ) {}

  // ---

  ngOnInit() {
    this.languagesOptions = this.inputOptionService.get('general', 'languages');
    this.selectedLanguage = this.appData.getAppData('cmsLanguage');
    this.placeholder = this.translateService.instant('common.actions.selectLanguage');
    this.onSelectLanguage(this.selectedLanguage);
  }

  // ---

  onSelectLanguage = e => {
    this.translateService.use(e);
    this.languageValues.emit(e);
  };
}
