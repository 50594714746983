import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { MessageService } from 'primeng/api';
import { AppDataService } from '../../../core/services/app-data.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonApi } from './../../../core/apis/common.api';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'media-box',
  templateUrl: './media-box.component.html',
  styleUrls: ['./media-box.component.scss']
})
export class MediaBoxComponent implements OnInit, OnChanges {
  @Input() imgData: any;
  @Input() isImage: boolean;
  @Output() clear = new EventEmitter<any>();
  @Output() updateCompleted = new EventEmitter<Event>();

  imageName: string;
  imageSrc: string;
  imageId: string;
  localization: any[];
  title: string;
  description: string;
  private: boolean;
  selectedLanguage: string;
  languagesOptions = [
    { label: 'English', value: 'en' },
    { label: 'Italian', value: 'it' }
  ];
  selectedTag: string[];
  tagsOptions: any[];
  cmsLanguage: string;
  sysLanguage: any;
  visible: boolean;
  roleOptions: any[] = [];
  selectedRoles: string[] = [];

  constructor(
    private mediaApi: MediaApi,
    private messageService: MessageService,
    protected appData: AppDataService,
    protected router: Router,
    protected translateService: TranslateService,
    protected commonApi: CommonApi
  ) {}

  ngOnInit(): void {
    this.tagsOptions = [
      { value: '', label: '-' },
      { value: 'cruiseShip', label: this.translateService.instant('media.tag.cruiseShip') },
      { value: 'navalVessels', label: this.translateService.instant('media.tag.navalVessels') },
      { value: 'ferry', label: this.translateService.instant('media.tag.ferry') },
      { value: 'shypYards', label: this.translateService.instant('media.tag.shypYards') },
      { value: 'megaYatch', label: this.translateService.instant('media.tag.megaYatch') },
      { value: 'repair', label: this.translateService.instant('media.tag.repair') },
      { value: 'offShore', label: this.translateService.instant('media.tag.offShore') },
      { value: 'systemAndComponents', label: this.translateService.instant('media.tag.systemAndComponents') },
      { value: 'events', label: this.translateService.instant('media.tag.events') }
    ];
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
    this.commonApi.getRoleList().subscribe((roles: any[]) => {
      const userRole = this.appData.getAppData('currentUser').roles[0].name;
      this.selectedRoles.push('ADMIN');
      this.selectedRoles.push('TECH');
      this.selectedRoles.push(userRole);
      roles.forEach((role: any) => {
        const optionRole = { value: role.name, label: role.name };
        if (optionRole.value === userRole || optionRole.value === 'ADMIN' || optionRole.value === 'TECH') {
          optionRole['disabled'] = true;
        }
        this.roleOptions.push(optionRole);
      });
    });
  }

  ngOnChanges() {
    if (this.imgData) {
      this.imageName = this.imgData.originalFileName ? this.imgData.originalFileName : this.imgData.fileName;
      this.imageSrc = `${environment.mediaUrl}${this.imgData.fileName}`;
      this.imageId = this.imgData.id;
      this.localization = this.imgData.localization || [];
      this.title = this.imgData.title;
      this.description = this.imgData.description;
      this.private = this.imgData.private;
      this.selectedTag = this.imgData.tag;
      this.selectedRoles = this.imgData.roles;
      this.selectedLanguage = 'en';
    } else {
      this.imageName = '';
      this.imageSrc = '';
      this.imageId = '';
      this.localization = [];
      this.title = '';
      this.description = '';
      this.private = false;
      this.selectedLanguage = 'en';
    }
  }

  onLanguageChange(event) {
    this.selectedLanguage = event.value;
    this.title = '';
    this.description = '';
    if (this.selectedLanguage === 'en') {
      this.title = this.imgData.title;
      this.description = this.imgData.description;
    } else {
      if (this.localization) {
        this.localization.forEach(el => {
          if (el.locale === this.selectedLanguage) {
            this.title = el.title;
            this.description = el.description;
          }
        });
      }
    }
  }

  onSave() {
    const data: any = { private: this.private };
    data.tag = this.selectedTag;
    data.roles = this.selectedRoles;

    if (this.selectedLanguage === 'en') {
      data.title = this.title;
      data.description = this.description;
    } else {
      if (this.localization.length > 0) {
        this.localization.forEach((el, i) => {
          if (el.locale === this.selectedLanguage) {
            this.localization.splice(i, 1);
          }
        });
      }
      this.localization.push({
        locale: this.selectedLanguage,
        title: this.title,
        description: this.description
      });
      data.localization = this.localization;
    }
    this.mediaApi.updateMedia(this.imageId, data).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Save Message', detail: 'Save Completed' });
      this.updateCompleted.emit();
    });
  }

  clearBox() {
    this.imageName = null;
    this.imageSrc = null;
    this.imageId = null;
    this.localization = null;
    this.title = null;
    this.description = null;
    this.selectedTag = [];
    this.selectedRoles = [];
    this.private = null;
    this.clear.emit({});
  }

  showConfirm() {
    this.messageService.clear();
    this.messageService.add({
      key: 'e',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure?',
      detail: 'Confirm to proceed'
    });
  }

  onConfirm() {
    this.mediaApi.deleteMedia(this.imageId).subscribe(() => {
      this.clearBox();
      this.messageService.add({ severity: 'success', summary: 'Delete Message', detail: 'Delete Completed' });
      setTimeout(() => {
        this.updateCompleted.emit();
      }, 1000);
    });
    this.messageService.clear('e');
  }

  onReject() {
    this.messageService.clear('e');
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe(language => {
      this.sysLanguage = language;
      this.visible = true;
    }); */
    this.visible = true;
  }
}
