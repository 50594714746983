import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { TemplateComponent } from '../../../../../core/dynamic-components/interfaces/template.component';
import { SlugService } from '../../../../../core/services/slug.service';

@Component({
  templateUrl: './post-common-fields.component.html'
})
export class PostCommonFieldsComponent implements AfterViewInit, TemplateComponent {
  @Input() data: any;
  @Output() outputData = new EventEmitter<any>();
  slug: string;
  title: string;

  id: string;

  constructor(protected slugService: SlugService) {}

  ngAfterViewInit(): void {
    if (this.data) {
      this.slug = this.data.slug;
      this.title = this.data.title;
      this.id = this.data.id;
    } else {
      this.getData();
    }
  }

  getData(): any {
    const data = {
      title: this.title ? this.title.trim() : null,
      slug: this.slug ? this.slug.trim() : null,
      id: this.id
    };
    return this.outputData.emit(data);
  }

  createSlug(v: string) {
    this.slug = this.slugService.createSlug(v);
    this.getData();
  }
}
