import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  code: number;
  constructor(
    protected ActivatedRoute: ActivatedRoute
  ) {
    this.ActivatedRoute.queryParams.subscribe(({ code }) => {
      this.code = code;
    })
  }

}
