import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MenuService } from 'src/app/core/services/menu.service';
import { PageWithSectionTitleComponent } from 'src/app/shared/components/page-with-section-title/page-with-section-title.component';
import { AppDataService } from '../../../core/services/app-data.service';
const env = require('src/environments/environment');
@Component({
  selector: 'media-ics',
  templateUrl: './media-ics.component.html',
  styleUrls: ['./media-ics.component.scss']
})
export class MediaIcsComponent extends PageWithSectionTitleComponent implements OnInit {
  @Input() selectIcsEnabled: boolean;
  @Output() icsClicked = new EventEmitter<any>();

  icsData: any;
  cmsLanguage: string;
  sysLanguage: any;
  visible: boolean;

  containerName: string;

  constructor(protected appData: AppDataService, protected menuService: MenuService) {
    super(menuService);
  }

  ngOnInit(): void {
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);

    this.containerName = env.environment.s3Bucket;
  }

  onSelectIcs() {
    if (this.selectIcsEnabled && this.icsData !== undefined) {
      this.icsClicked.emit(this.icsData);
    }
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe(language => {
      this.sysLanguage = language;
      this.visible = true;
    }); */
    this.visible = true;
  }
}
