import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FeedApi {
  constructor(protected httpClient: HttpClient) {}

  getFeeds(filter = '') {
    return this.httpClient.get('network-content/feed', {
      params: {
        filter
      }
    });
  }

  createFeed(feed) {
    return this.httpClient.post('network-content/feed', feed);
  }

  updateFeed(id, feed) {
    return this.httpClient.put(`network-content/feed/${id}`, feed);
  }

  deleteFeed(id) {
    return this.httpClient.delete(`network-content/feed/${id}`);
  }

  importFromFeed(feed) {
    return this.httpClient.post(`network-content/press-content-feed/import/${feed}`, {});
  }

  getFeedImportHistory(filter = '') {
    return this.httpClient.get('network-content/press-content-feed', {
      params: {
        filter
      }
    });
  }

  getFeedImportHistoryCount(filter = '') {
    return this.httpClient.get('network-content/press-content-feed/count', {
      params: {
        filter
      }
    });
  }
}
