import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logs, Severities } from '../models/logs-model';
import { LazyLoadEvent } from 'primeng/primeng';
@Injectable()
export class LogsApi {
  constructor(protected httpClient: HttpClient) {}
  getIssues(filters: string[]) {
    return this.httpClient.get<Logs>(`log/log?${filters ? filters.join('&') : ''}`);
  }
  postIssue(body) {
    return this.httpClient.post<Logs>('log/log', body);
  }
  getSeverities(params?: LazyLoadEvent) {
    const queryParams: { sort?: any; severity?: any } = {};

    if (params && params.sortField && params.sortOrder) {
      queryParams.sort = `${params.sortField}:${params.sortOrder}`;
    }
    if (params && params.filters && params.filters.severity) {
      queryParams.severity = params.filters.severity && params.filters.severity.value;
    }
    return this.httpClient.get<Severities>(`log/scope-severity/`, {
      params: queryParams
    });
  }
  updateSeverity(id, severity) {
    return this.httpClient.patch(`log/scope-severity/${id}`, { severity });
  }
  getSeveritiesCount() {
    return this.httpClient.get<{ count: number }>(`log/scope-severity/count`);
  }
  getLogsCount() {
    return this.httpClient.get<{ count: number }>(`log/log/count`);
  }
}
