import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, AfterViewChecked, EventEmitter, Output, ChangeDetectorRef, Input } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { AppDataService } from '../../../core/services/app-data.service';
import { MessageService } from 'primeng/api';
const env = require('src/environments/environment');
@Component({
  selector: 'media-ics-view',
  templateUrl: './media-ics-view.component.html',
  styleUrls: ['./media-ics-view.component.scss']
})
export class MediaIcsViewComponent implements OnInit, AfterViewChecked {
  @Input() selectIcsEnabled;
  @Output() icsClicked = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  icsId: string;
  icsList: any[];
  totalRecords: number;
  sortOptions: any[];
  sortOrder: number;
  sortField: string;
  filterBy: string;
  loading: boolean;
  baseUrl: string = env.environment.mediaUrl;
  visible: boolean;
  cmsLanguage: string;
  sysLanguage: any;
  containerName: string;
  tagOptions: any[];
  filterByTag: string[];
  folders: any[];
  filterByFolder: string;
  userRole: string;

  constructor(
    private mediaApi: MediaApi,
    private cdRef: ChangeDetectorRef,
    protected appData: AppDataService,
    private messageService: MessageService,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.containerName = env.environment.s3Bucket;
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterBy = '';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
    this.userRole = this.appData.getAppData('currentUser').roles[0].name;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onClearFilters() {
    this.filterBy = '';
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.refresh.emit({});
    this.icsClicked.emit(undefined);
    this.updateDataView();
  }

  updateDataView() {
    this.visible = false;
    this.icsList = [];
    setTimeout(() => (this.visible = true), 0);
  }

  lazyLoadingIcs(event) {
    this.loading = true;

    const filterCount = [];
    const filters = [];
    // filters.push('filter[offset]=' + event.first);
    // filters.push('filter[limit]=' + (event.first + event.rows));
    filters.push('filter[where][type][regexp]=/calendar/');
    filterCount.push('where[type][regexp]=/calendar/');

    if (this.sortField !== null && this.sortOrder !== null) {
      const ordering = this.sortOrder === -1 ? ' DESC' : ' ASC';
      filters.push('filter[order]=' + this.sortField + ordering);
    }

    filters.push('filter[where][containerName]=' + this.containerName);
    filterCount.push('where[containerName]=' + this.containerName);

    filters.push('filter[where][roles][inq]=' + this.userRole);
    filterCount.push('where[roles][inq]=' + this.userRole);

    if (this.filterByFolder.length > 0 && this.filterByFolder !== 'root') {
      filters.push('filter[where][folder]=' + this.filterByFolder);
      filterCount.push('where[folder]=' + this.filterByFolder);
    }

    if (this.filterBy !== '') {
      filters.push('filter[where][or][0][fileName][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][1][title][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][2][description][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][3][folder][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][4][originalFileName][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][0][fileName][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][1][title][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][2][description][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][3][folder][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][4][originalFileName][regexp]=/' + this.filterBy + '/i');
    }

    if (this.filterByTag.length > 0) {
      this.filterByTag.forEach(tag => {
        filters.push(`filter[where][tag][inq]=${tag}`);
        filterCount.push(`where[tag][inq]=${tag}`);
      });
    }

    // this.mediaApi.getImagesCount(filterCount).subscribe((res: any) => {
    //   this.totalRecords = res.count;
    // });

    this.mediaApi.getIcs(filters).subscribe((res: any) => {
      //this.icsList = res;
      //this.icsList.forEach((ics, i) => {
      //  this.icsList[i].src = this.baseUrl + ics.fileName + this.apiKey;
      //});
      this.icsList = this.filterByFolder ? res.map(this.mapIcsInFolder) : this.mapIcsFolder(res);
      this.totalRecords = this.icsList.length;
      this.icsList = this.icsList.slice(event.first);
      this.loading = false;
    });
  }

  mapIcsInFolder = (ics, i) => {
    return { ...ics, src: this.baseUrl + ics.fileName };
  };

  mapIcsFolder = res => {
    const images = {};
    res.forEach(curr => {
      const key = curr.folder ? curr.folder : 'root';
      const currFold = images[key] ? images[key] : [];
      currFold.push({ folder: key });
      images[key] = currFold;
    });
    const folders = Object.keys(images).map(curr => {
      return { folder: curr, tot: images[curr].length };
    });
    return folders.sort((a, b) => {
      return a.folder > b.folder ? 1 : a.folder < b.folder ? -1 : 0;
    });
  };

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  open(folder) {
    this.filterByFolder = folder;
    this.updateDataView();
  }

  onIcsClick(icsData) {
    this.icsClicked.emit(icsData);
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe((language: any) => {
      this.sysLanguage = language;
      this.sortOptions = [
        { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
        { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
        { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
        { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
      ];
      this.visible = true;
    }); */
    this.sortOptions = [
      { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
      { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
      { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
      { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
    ];
    this.visible = true;
  }

  showConfirm(icsId) {
    this.icsId = icsId;
    this.messageService.clear();
    this.messageService.add({
      key: 'g',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure?',
      detail: 'Confirm to proceed'
    });
  }

  onConfirm() {
    this.mediaApi.deleteMedia(this.icsId).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Delete Message', detail: 'Delete Completed' });
      setTimeout(() => {
        this.updateDataView();
      }, 1000);
    });
    this.messageService.clear('g');
  }

  onReject() {
    this.icsId = undefined;
    this.messageService.clear('g');
  }
}
