import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { MediaMainComponent } from './components/media-main/media-main.component';
import { MediaDataViewComponent } from './components/media-data-view/media-data-view.component';
import { MediaPdfViewComponent } from './components/media-pdf-view/media-pdf-view.component';
import { MediaIcsViewComponent } from './components/media-ics-view/media-ics-view.component';
import { MediaUploadComponent } from './components/media-upload/media-upload.component';
import { MediaUploadPdfComponent } from './components/media-upload-pdf/media-upload-pdf.component';
import { MediaUploadIcsComponent } from './components/media-upload-ics/media-upload-ics.component';
import { MediaPanelComponent } from './components/media-panel/media-panel.component';
import { MediaBoxComponent } from './components/media-box/media-box.component';
import { MediaPdfComponent } from './components/media-pdf/media-pdf.component';
import { MediaIcsComponent } from './components/media-ics/media-ics.component';
import { MediaVideoComponent } from './components/media-video/media-video.component';
import { MediaVideoViewComponent } from './components/media-video-view/media-video-view.component';
import { MediaUploadVideoComponent } from './components/media-upload-video/media-upload-video.component';
import { PrimengModulesModule } from '../primeng-modules/primeng-modules.module';

const mediaRoutes: Routes = [
  {
    path: 'media',
    children: [
      { path: 'pdf', component: MediaPdfComponent },
      { path: 'ics', component: MediaIcsComponent },
      { path: 'video', component: MediaVideoComponent },
      { path: ':route', redirectTo: 'media-panel', pathMatch: 'full' },
      { path: 'media-panel', component: MediaPanelComponent }
    ]
  },
  {
    path: 'fincantieri-si-media',
    children: [
      { path: 'pdf', component: MediaPdfComponent },
      { path: 'video', component: MediaVideoComponent },
      { path: ':route', redirectTo: 'images', pathMatch: 'full' },
      { path: 'images', component: MediaPanelComponent }
    ]
  }
  // {
  //   path: 'portale-giornalisti',
  //   children: [
  //     { path: 'video', component: MediaVideoComponent },
  //     { path: ':route', redirectTo: 'images' },
  //     { path: 'images', component: MediaPanelComponent }
  //   ]
  // }
];

// TODO: Valutare gestione immagini e video

@NgModule({
  declarations: [
    MediaMainComponent,
    MediaDataViewComponent,
    MediaUploadComponent,
    MediaPanelComponent,
    MediaBoxComponent,
    MediaPdfComponent,
    MediaIcsComponent,
    MediaIcsViewComponent,
    MediaUploadIcsComponent,
    MediaPdfViewComponent,
    MediaUploadPdfComponent,
    MediaVideoComponent,
    MediaVideoViewComponent,
    MediaUploadVideoComponent
  ],
  imports: [CommonModule, FormsModule, RouterModule.forChild(mediaRoutes), PrimengModulesModule, TranslateModule],
  exports: [
    MediaMainComponent,
    MediaDataViewComponent,
    MediaUploadComponent,
    MediaPanelComponent,
    MediaBoxComponent,
    MediaPdfComponent,
    MediaPdfViewComponent,
    MediaUploadPdfComponent,
    MediaVideoComponent,
    MediaVideoViewComponent,
    MediaUploadVideoComponent,
    MediaIcsComponent,
    MediaIcsViewComponent,
    MediaUploadIcsComponent,
    RouterModule
  ]
})
export class MediaModule {}
