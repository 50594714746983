import { Component, Output, Input, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { MessageService } from 'primeng/api';
import { AppDataService } from '../../../core/services/app-data.service';
import { CommonApi } from './../../../core/apis/common.api';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';

const env = require('src/environments/environment');

@Component({
  selector: 'media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss']
})
export class MediaUploadComponent implements OnInit {
  @Input() allFolder: [any];
  @Input() currentFolder: string;
  optionsFolder: any[];
  @Output() uploadCompleted: EventEmitter<Event> = new EventEmitter();
  cmsLanguage: string;
  sysLanguage: any;
  visible: boolean;
  containerName: string;
  showUploadArea: boolean;
  chooseTypeFolder: string;
  newFolder: string;
  newFolderInvalid: boolean;
  oldFolder: string;
  selectedFolder: string;
  roleOptions: any[] = [];
  selectedRoles: string[] = ['ADMIN', 'TECH'];
  uploadProgress: string[] = [];
  userId: string;
  roleId: string;
  @ViewChild('upload', /* TODO: add static flag */  { static: false }) uploadControl: FileUpload;

  constructor(
    private mediaApi: MediaApi,
    private messageService: MessageService,
    protected appData: AppDataService,
    protected commonApi: CommonApi
  ) {}

  ngOnInit(): void {
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
    this.showUploadArea = false;
    this.containerName = env.environment.s3Bucket;
    this.newFolderInvalid = false;
    this.commonApi.getRoleList().subscribe((roles: any[]) => {
      const userRole = this.appData.getAppData('currentUser').roles[0].name;
      if (userRole !== 'ADMIN' && userRole !== 'TECH') this.selectedRoles.push(userRole);
      roles.forEach((role: any) => {
        const optionRole = { value: role.name, label: role.name };
        if (optionRole.value === userRole || optionRole.value === 'ADMIN' || optionRole.value === 'TECH') {
          optionRole['disabled'] = true;
        }
        this.roleOptions.push(optionRole);
      });
    });
    this.userId = this.appData.getAppData('currentUser').userId;
    this.roleId = this.appData.getAppData('currentUser').roles[0].id;
  }

  showMeUploadArea() {
    this.showUploadArea = !this.showUploadArea;
    if (this.currentFolder) {
      this.chooseTypeFolder = 'folderOld';
      this.selectedFolder = this.currentFolder;
    }
    if (this.showUploadArea) {
      this.optionsFolder = this.allFolder
        .filter(curr => curr.folder !== 'root')
        .map(curr => {
          return {
            label: curr.folder,
            value: curr.folder
          };
        });
    } else {
      this.chooseTypeFolder = '';
      this.uploadProgress = [];
      this.onClick(null);
    }
  }

  checkFolder() {
    this.newFolderInvalid = this.optionsFolder.map(curr => curr.value).some(curr => curr === this.newFolder);
  }

  onClick(choose) {
    this.selectedFolder = '';
    this.newFolder = '';
    this.newFolderInvalid = false;
    if (choose === 'folderOld' && this.optionsFolder.length === 1) {
      this.selectedFolder = this.optionsFolder[0].value;
    }
  }

  getEventMessage(event: HttpEvent<any>, file: File) {
    if (!event) return '';
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return `Uploading file  "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `Uploading file  "${file.name}" was completely uploaded!`;

      default:
        return `Uploading file  "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  uploadZip(zipFile, upload) {
    const mediaData = {
      containerName: this.containerName,
      folder: this.newFolder ? this.newFolder : this.selectedFolder,
      roles: this.selectedRoles,
      userId: this.userId,
      roleId: this.roleId
    };
    const formData = new FormData();
    formData.append('zip', zipFile, zipFile.name);
    formData.append('mediaData', JSON.stringify(mediaData));
    this.mediaApi.uploadZip(formData).subscribe(
      (res: any) => {
        const { invalidFiles, errors, validFiles } = res;

        if (validFiles.length) {
          this.messageService.add({
            severity: 'success',
            summary: 'Upload Completed',
            detail: `${validFiles.length} files uploaded`
          });
        }

        if (errors.length <= 2) {
          errors.forEach(errorFile => {
            this.messageService.add({
              severity: 'error',
              summary: `File not uploaded`,
              detail: `${errorFile}`,
              closable: true
            });
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: `${errors.length} files in the archive were not uploaded`,
            closable: true
          });
        }

        if (invalidFiles.length <= 2) {
          invalidFiles.forEach(invalidFile => {
            this.messageService.add({
              severity: 'warn',
              summary: `Invalid file`,
              detail: `${invalidFile}`,
              closable: true
            });
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: `Warning`,
            detail: `${invalidFiles.length} files in the archive are invalid`,
            closable: true
          });
        }
        this.onUploadFinish(upload);
      },
      err => {
        this.messageService.add({
          severity: 'error',
          summary: `${err.message}`,
          closable: true
        });
        this.onUploadFinish(upload);
      }
    );
  }

  onUploadFinish(upload) {
    upload.clear();
    setTimeout(() => {
      this.uploadCompleted.emit();
      this.showMeUploadArea();
    }, 2000);
  }

  uploadImages(event, upload) {
    event.files.forEach((file, idx) => {
      this.mediaApi.getPresignedUrl(file.name, file.type, file.size).subscribe(
        (resp: any) => {
          this.mediaApi.postFileToS3(resp.url, file).subscribe((event: any) => {
            //  if (!event) return;
            //  this.uploadProgress[idx] = this.getEventMessage(event, file);
            //    if (event.type === HttpEventType.Response) {
            const mediaData = {
              containerName: this.containerName,
              fileName: resp.newFilename,
              type: file.type,
              folder: this.newFolder ? this.newFolder : this.selectedFolder,
              roles: this.selectedRoles,
              originalFileName: file.name,
              size: file.size,
              userId: this.userId,
              roleId: this.roleId
            };
            this.mediaApi.uploadMedia(mediaData).subscribe(() => {
              this.messageService.add({
                severity: 'success',
                summary: 'Upload Message',
                detail: 'Upload Completed'
              });
              this.onUploadFinish(upload);
            });
            //}
          });
        },
        ({ error }) => {
          this.messageService.add({
            summary: 'Error while uploading file',
            severity: 'error',
            detail: error.message
          });
          this.uploadControl.clear();
        }
      );
    });
  }

  onUpload(event, upload) {
    if (event.files && event.files.length) {
      const zipFile = event.files[0];
      if (zipFile.type === 'application/zip') {
        this.uploadZip(zipFile, upload);
      } else {
        this.uploadImages(event, upload);
      }
    }
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe(language => {
      this.sysLanguage = language;
      this.visible = true;
    }); */
    this.visible = true;
  }
}
