import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  constructor(protected authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const currentUser = this.authService.currentUserValue;
    // const headers = currentUser
    //   ? { apikey: environment.apiKey, Authorization: currentUser.id }
    //   : { apikey: environment.apiKey };

    // if (! req.url.match(/assets/)) {
    //   req = req.clone({ setHeaders: headers });
    // }

    return next.handle(req);
  }
}
