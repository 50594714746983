import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';

import { MessageService } from 'primeng/api';
import { AppDataService } from '../../../core/services/app-data.service';
import { CommonApi } from './../../../core/apis/common.api';
const env = require('src/environments/environment');
import { HttpEvent, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'media-upload-ics',
  templateUrl: './media-upload-ics.component.html',
  styleUrls: ['./media-upload-ics.component.scss']
})
export class MediaUploadIcsComponent implements OnInit {
  @Input() allFolder: [any];
  @Input() currentFolder: string;
  optionsFolder: any[];
  @Output() uploadCompleted: EventEmitter<Event> = new EventEmitter();
  cmsLanguage: string;
  sysLanguage: any;
  visible: boolean;
  containerName: string;
  showUploadArea: boolean;
  chooseTypeFolder: string;
  newFolder: string;
  newFolderInvalid: boolean;
  oldFolder: string;
  selectedFolder: string;
  roleOptions: any[] = [];
  selectedRoles: string[] = ['ADMIN', 'TECH'];
  uploadProgress: string[] = [];
  userId: string;
  roleId: string;

  constructor(
    private mediaApi: MediaApi,
    private messageService: MessageService,
    protected appData: AppDataService,
    protected commonApi: CommonApi
  ) {}

  ngOnInit(): void {
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
    this.showUploadArea = false;
    this.containerName = env.environment.s3Bucket;
    this.newFolderInvalid = false;
    this.chooseTypeFolder = '';
    this.commonApi.getRoleList().subscribe((roles: any[]) => {
      const userRole = this.appData.getAppData('currentUser').roles[0].name;
      if (userRole !== 'ADMIN' && userRole !== 'TECH') this.selectedRoles.push(userRole);
      roles.forEach((role: any) => {
        const optionRole = { value: role.name, label: role.name };
        if (optionRole.value === userRole || optionRole.value === 'ADMIN' || optionRole.value === 'TECH') {
          optionRole['disabled'] = true;
        }
        this.roleOptions.push(optionRole);
      });
    });
    this.userId = this.appData.getAppData('currentUser').userId;
    this.roleId = this.appData.getAppData('currentUser').roles[0].id;
  }

  showMeUploadArea() {
    this.showUploadArea = !this.showUploadArea;
    if (this.currentFolder) {
      this.chooseTypeFolder = 'folderOld';
      this.selectedFolder = this.currentFolder;
    }
    if (this.showUploadArea) {
      this.optionsFolder = this.allFolder
        .filter(curr => curr.folder !== 'root')
        .map(curr => {
          return {
            label: curr.folder,
            value: curr.folder
          };
        });
    } else {
      this.chooseTypeFolder = '';
      this.uploadProgress = [];
      this.onClick(null);
    }
  }

  checkFolder() {
    this.newFolderInvalid = this.optionsFolder.map(curr => curr.value).some(curr => curr === this.newFolder);
  }

  onClick(choose) {
    this.selectedFolder = '';
    this.newFolder = '';
    this.newFolderInvalid = false;
    if (choose === 'folderOld' && this.optionsFolder.length === 1) {
      this.selectedFolder = this.optionsFolder[0].value;
    }
  }

  getEventMessage(event: HttpEvent<any>, file: File) {
    if (!event) return '';
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        return `Uploading file  "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        return `Uploading file  "${file.name}" was completely uploaded!`;

      default:
        return `Uploading file  "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  onUpload(event, upload) {
    event.files.forEach((file, idx) => {
      this.mediaApi.getPresignedUrl(file.name, file.type, file.size).subscribe(
        (resp: any) => {
          this.mediaApi.postFileToS3(resp.url, file).subscribe((event: any) => {
            if (!event) return;
            this.uploadProgress[idx] = this.getEventMessage(event, file);
            if (event.type === HttpEventType.Response) {
              const mediaData = {
                containerName: this.containerName,
                fileName: resp.newFilename,
                type: file.type,
                folder: this.newFolder ? this.newFolder : this.selectedFolder,
                roles: this.selectedRoles,
                originalFileName: file.name,
                size: file.size,
                userId: this.userId,
                roleId: this.roleId
              };
              this.mediaApi.uploadMedia(mediaData).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Upload Message', detail: 'Upload Completed' });
                upload.clear();
                setTimeout(() => {
                  this.uploadCompleted.emit();
                  this.showMeUploadArea();
                }, 1000);
              });
            }
          });
        },
        error => {
          console.log(error);
          this.messageService.add({ severity: 'error', summary: 'Upload Failed', detail: 'Upload NOT Completed' });
          upload.clear();
          setTimeout(() => {
            this.uploadCompleted.emit();
            this.showMeUploadArea();
          }, 1000);
        }
      );
    });
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe(language => {
      this.sysLanguage = language;
      this.visible = true;
    }); */
    this.visible = true;
  }
}
