import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable()
export class MediaApi {
  constructor(protected httpClient: HttpClient) {}

  getImagesCount(filterCount) {
    filterCount.push('ts=' + Date.now());
    return this.httpClient.get('network-content/medias/count/?' + filterCount.join('&'));
  }

  getImages(filters) {
    filters.push('ts=' + Date.now());
    return this.httpClient.get('network-content/medias/?' + filters.join('&'));
  }

  getPdf(filters) {
    filters.push('ts=' + Date.now());
    return this.httpClient.get('network-content/medias/?' + filters.join('&'));
  }

  getIcs(filters) {
    filters.push('ts=' + Date.now());
    return this.httpClient.get('network-content/medias/?' + filters.join('&'));
  }

  getVideos(filters) {
    filters.push('ts=' + Date.now());
    return this.httpClient.get('network-content/medias/?' + filters.join('&'));
  }

  getImage(id) {
    return this.httpClient.get(`network-content/medias/${id}`);
  }

  uploadFile(file, containerName) {
    return this.httpClient.post(`network-content/containers/${containerName}/upload/`, file);
  }

  getPresignedUrl(filename, mimeType, size) {
    // WORKAROUND: The integration between API Gateway and EC2 fails (400) when the filename, included in the endpoint, consists of a mix of spaces and minus
    const noSpaceFilename = filename.replaceAll(' ', '');
    const formattedFileName = encodeURIComponent(noSpaceFilename);
    return this.httpClient.get(`network-content/medias/getPresignedUrl/${formattedFileName}`, {
      params: new HttpParams({ fromString: `type=${mimeType}&size=${size}` })
    });
  }
  uploadZip(zip) {
    return this.httpClient.post(`network-content/medias/uploadzip`, zip);
  }
  postFileToS3(endpoint, form) {
    return this.httpClient.put(endpoint, form);
  }

  uploadMedia(mediaData) {
    return this.httpClient.post('network-content/medias/', mediaData);
  }

  deleteMedia(id) {
    return this.httpClient.delete('network-content/medias/' + id);
  }

  updateMedia(id, data) {
    return this.httpClient.patch('network-content/medias/' + id, data);
  }
}
