<div class="footer" *ngIf="!prod">
  <div class="grid">
    <div class="col-12">
      <div class="card clearfix">
        <span class="footer-text-left pull-left">{{ packageName }} v{{ version }}-{{ build }}</span>
        <span class="footer-text-right pull-right">
          <span class="ui-icon ui-icon-copyright"></span><span>All Rights Reserved</span>
        </span>
      </div>
    </div>
  </div>
</div>
