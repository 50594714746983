import { Component } from '@angular/core';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  email: string;

  resetPassword = () => {
    // TODO: ADD API Method for reset password
    this.email = '';
  };
}
