<div class="grid" *ngIf="visible">
  <div class="col-12">
    <h1>{{ title }}</h1>
  </div>
  <div *ngIf="selectVideoEnabled" class="col-12 flex justify-content-end">
    <button
      [disabled]="!videoData"
      pButton
      type="button"
      icon="fa fa-check"
      [label]="'common.actions.selectVideo' | translate | titlecase"
      (click)="onSelectVideo()"
    ></button>
  </div>
  <div class="col-12 md:col-9">
    <media-video-view
      [selectVideoEnabled]="selectVideoEnabled"
      (refresh)="videoData = $event"
      (videoClicked)="videoData = $event"
      #dataView
    >
    </media-video-view>
  </div>
  <div class="col-12 md:col-3">
    <media-box
      [imgData]="videoData"
      (updateCompleted)="dataView.updateDataView()"
      (clear)="videoData = $event"
    >
    </media-box>
  </div>
</div>
