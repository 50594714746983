<div class="grid" *ngIf="visible">
  <div class="col-12 p-md-6">
    <h2>{{ title }}</h2>
  </div>
</div>
<div class="grid">
  <div class="col-12">
    <div class="col-12">
      <div class="col-12">
        <media-pdf-view [selectPdfEnabled]="selectPdfEnabled" (refresh)="pdfData = $event"
          (pdfClicked)="pdfData = $event; onSelectPdf()" #dataView>
        </media-pdf-view>
      </div>
    </div>
  </div>
</div>
