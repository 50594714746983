import { AppDataService } from './../services/app-data.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CommonApi {
  constructor(protected httpClient: HttpClient, protected appData: AppDataService) {}

  getMenu() {
    return this.httpClient.get('auth/bo-menu/nest');
  }

  // User methods
  createUser(data) {
    return this.httpClient.post('auth/bo-users', data);
  }

  getUser(userID) {
    return this.httpClient.get('auth/bo-users/user-details/' + userID);
  }

  getUsersList() {
    return this.httpClient.get('auth/bo-users?filter={"where":{"source": "CMS"}}');
  }

  getCompleteUsersList() {
    return this.httpClient.get('auth/bo-users');
  }

  updateUser(userID, data) {
    return this.httpClient.patch('auth/bo-users/user-details/' + userID, data);
  }

  getUserRole(userID) {
    return new Observable(observer => {
      observer.next(this.appData.getAppData('currentUser').roles);
    });
  }

  getUsersRole(userID) {
    return this.httpClient.get('auth/bo-users/' + userID + '/roles');
  }

  getRoleList() {
    return this.httpClient.get<any[]>('auth/Roles');
  }

  getRoleByName(roleName) {
    return this.httpClient.get('auth/Roles?filter[where][name]=' + roleName);
  }

  createUserRole(userID, roleID) {
    return this.httpClient.post('auth/RoleMappings', { principalId: userID, roleId: roleID });
  }

  updateUserRole(userID, roleID) {
    return this.httpClient.post('auth/RoleMappings/update?where[principalId]=' + userID, { roleId: roleID });
  }

  updatePassword(data) {
    return this.httpClient.put('auth/bo-users/update-password', data);
  }
}
