import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './media-main.component.html',
  styleUrls: ['./media-main.component.scss']
})
export class MediaMainComponent {
  constructor(protected router: Router, protected route: ActivatedRoute) {}

  goMediaPanel() {
    // this.router.navigate(['media-panel']);
  }
}
