<div class="grid" *ngIf="visible">
  <div class="col-9">
    <h2>{{ title }}</h2>
  </div>
  <div *ngIf="selectImageEnabled" class="sm:col-12 md:col-3 flex justify-content-end">
    <button
      [disabled]="!imageData"
      pButton
      type="button"
      icon="fa fa-check"
      [label]="'common.actions.selectImage' | translate | titlecase"
      class="btn-info pull-right"
      (click)="onSelectImage()"
    ></button>
  </div>
  <div class="sm:col-12 md:col-9">
    <media-data-view
      (refresh)="imageData = $event"
      (imageClicked)="imageData = $event"
      #dataView
    ></media-data-view>
  </div>
  <div class="sm:col-12 md:col-3">
    <media-box
      [isImage]="true"
      [imgData]="imageData"
      (updateCompleted)="dataView.updateDataView()"
      (clear)="imageData = $event"
    >
    </media-box>
  </div>
</div>
