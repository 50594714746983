import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApprovalFlowGrades, ApprovalFLowUser } from '../models/approval-flow';

@Injectable()
export class ApprovalFlowApi {
  constructor(protected httpClient: HttpClient) {}

  getApprovalFlowDEMs(skip, limit) {
    return this.httpClient.get(`network-content/approval-flow/all?filter={"skip":${skip},"limit":${limit}}`);
  }
  getApprovalFlowDEMsCount() {
    return this.httpClient.get<{ count: number }>('network-content/approval-flow/count');
  }

  getPendingDEMs(skip, limit) {
    return this.httpClient.get(`network-content/approval-flow/pending?filter={"skip":${skip},"limit":${limit}}`);
  }
  getPendingDEMsCount() {
    return this.httpClient.get<{ count: number }>('network-content/approval-flow/pending/count');
  }

  approveDEM(id) {
    return this.httpClient.post(`network-content/approval-flow/${id}/approve`, {});
  }
  rejectDEM(id, notes) {
    return this.httpClient.post(`network-content/approval-flow/${id}/reject`, { notes });
  }
  resetStatus(id)  {
    return this.httpClient.post(`network-content/approval-flow/${id}/reset`, {});
  }

  sendToApprover(content: string, locale: string) {
    return this.httpClient.post('network-content/approval-flow', {
      content,
      locale
    });
  }

  getUsersWithApprovalFlowRole(filters?) {
    return this.httpClient.get<ApprovalFLowUser[]>(
      `auth/user-approval-roles?${filters ? filters.join('&') : ''}`
    );
  }

  getAllApprovalFlowRoleUsers() {
    return this.httpClient.get<ApprovalFLowUser[]>(`auth/user-approval-roles/approver-users`);
  }

  getApprovalFlowGrades() {
    return this.httpClient.get<ApprovalFlowGrades>(`auth/user-approval-roles/grades`);
  }

  getUsersWithApprovalFlowRoleCount(filters) {
    return this.httpClient.get<any>(`auth/user-approval-roles/count?${filters ? filters.join('&') : ''}`);
  }

  updateUserGrade(id: string, grade: string) {
    return this.httpClient.put(`auth/user-approval-roles/grades`, { id, grade });
  }

  getUserGradeAndRole() {
    return this.httpClient.get<{ grade: string; role: string }>(`auth/user-approval-roles/user-data`);
  }

  getTemplateApprovers(slug: string) {
    return this.httpClient.get<{ approvers: string[], role: string }>(`network-content/mail-templates/approvers?slug=${slug}`);
  }
}
