import { Injectable } from '@angular/core';

@Injectable()
export class SchemasService {
  getSchemaByContentType(contentType) {
    switch (contentType) {
      case 'mailTemplatesContent':
        return '../../../../../assets/schemas/mail/mail-templates.json';
      case 'pressReviewsDSContent':
        return '../../../../../assets/schemas/press/press-reviews-ds.json';
      case 'pressReviewsAdnkContent':
        return '../../../../../assets/schemas/press/press-adnk.json';
      case 'pressReviewsLaBussolaContent':
        return '../../../../../assets/schemas/press/press-la-bussola.json';
      case 'fincantieriSIContent':
      case 'fincantieriInfrastructureContent':
      case 'opereMarittimeContent':
      case 'finsoContent':
      case 'sofContent':
        return '../../../../../assets/schemas/press/site.json';
      case 'pressReviewsContent':
      case 'pressReleasesContent':
      case 'cioCommunicationsContent':
      case 'pressCommunicationsContent':
      case 'pressBruxellesBubble':
        return '../../../../../assets/schemas/press/press-content.json';
      default:
        return '';
    }
  }

  // checkSchemaEndpoint(schema, endpoint) {
  //   const schemaEndpoint = {
  //     'mail-templates.json': 'mail-templates',
  //     'fincantieri-si.json': 'fincantieri-si',
  //     'press-content.json': 'press-content'
  //   };
  //   return schemaEndpoint[schema] === endpoint;
  // }
}
