<p-dialog
  dismissableMask="true"
  blockScroll="true"
  [draggable]="false"
  [closeOnEscape]="true"
  [header]="'Mail addresses list'"
  [(visible)]="displayAddressModal"
  [modal]="true"
  appendTo="body"
  (onHide)="onHide()"
>
  <div [ngStyle]="{ 'min-width': '300px' }" class="grid">
    <tr *ngFor="let mail of formattedToList">
      <td>
        <i [ngStyle]="{ color: '#337ab7', marginRight: '5px' }" class="fa fa-envelope"></i>
        <label class="nav-item">{{ mail }}</label>
      </td>
    </tr>
  </div>
</p-dialog>
