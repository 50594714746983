import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { ContentManagementApi } from "../../apis/content-management-api.service";
import { LogsApi } from 'src/app/core/apis/logs.api';
import { SchemasService } from "../schemas.service";
import { lastValueFrom } from "rxjs";

@Injectable()
export class MailTemplateService {
  constructor(
    protected contentManagement: ContentManagementApi,
    protected schemasService: SchemasService,
    protected messageService: MessageService,
    protected logsApi: LogsApi
  ) {}
  events: any[];

  private isEmptyContent(data) {
    return (
      !data.content ||
      Object.entries(data.content).length === 0 ||
      (data.content.blocks && data.content.blocks.length === 0)
    );
  }
  setEvents(e) {
    this.events = e;
  }

  async createContent(endpoint, data, contentId?, schema?, user?, currentUserRole?) {
    try {
      // if (!this.schemasService.checkSchemaEndpoint(schema, endpoint)) {
      //   this.messageService.add({
      //     severity: 'error',
      //     summary: 'Error',
      //     detail: 'Invalid Endpoint'
      //   });
      //   return false;
      // }
      if (data.id && this.isEmptyContent(data)) {
        throw new Error('Content cannot be empty');
      }
      if (contentId) {
        delete data.id;
        delete data.revision;
        delete data.active;
        data.original = data.original || contentId;
      }
      const content: any = await lastValueFrom(this.contentManagement.createNewContent(endpoint, data));
      this.messageService.add({
        severity: 'success',
        summary: 'Success Message',
        detail: 'Save Completed'
      });
      contentId = content.id;
      return contentId;
    } catch (error1) {
      let mex = error1.message || (error1.error && error1.error.message);
      if (mex === 'Slug cannot be duplicated on same locale') {
        mex = `Titolo (${data.title}) già presente, indicarne uno diverso`;
      }
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: mex
      });
      await lastValueFrom(this.logsApi.postIssue({
        role: currentUserRole,
        user,
        userId: data.createdBy,
        feature: 'pressContentSaveError',
        message: `User ${user} non è riuscito a salvare la DEM ${data.title}`,
        cause: mex
      }));
    }
  }
}
