import { AppDataService } from './../services/app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotificationsApi {
  constructor(protected httpClient: HttpClient, protected appData: AppDataService) {}

  getNotifications(filters) {
    return this.httpClient.get<any[]>(`notification/?${filters ? filters.join('&') : ''}`);
  }
  getNotificationById(id) {
    return this.httpClient.get<any>(`notification/${id}`);
  }
  getUnreadNotificationsCount() {
    return this.httpClient.get<any>(`notification/unread`);
  }
  getTotalRecordsCount(filters){
    return this.httpClient.get<any>(
      `notification/count?${filters ? filters.join('&') : ''}`
    );

  }
}
