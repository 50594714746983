import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, AfterViewChecked, EventEmitter, Output, ChangeDetectorRef, Input } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
const env = require('src/environments/environment');
import { AppDataService } from '../../../core/services/app-data.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'media-video-view',
  templateUrl: './media-video-view.component.html',
  styleUrls: ['./media-video-view.component.scss']
})
export class MediaVideoViewComponent implements OnInit, AfterViewChecked {
  @Input() selectVideoEnabled;
  @Output() videoClicked = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  videoId: string;
  videoList: any[];
  totalRecords: number;
  sortOptions: any[];
  sortOrder: number;
  sortField: string;
  filterBy: string;
  loading: boolean;
  apiKey: string;
  visible: boolean;
  cmsLanguage: string;
  sysLanguage: any;
  containerName: string;
  selectedVideoId = '';
  tagOptions: any[];
  filterByTag: string[];
  folders: any[];
  filterByFolder: string;
  userRole: string;

  constructor(
    private mediaApi: MediaApi,
    private cdRef: ChangeDetectorRef,
    protected appData: AppDataService,
    private messageService: MessageService,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterBy = '';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);

    this.containerName = env.environment.s3Bucket;

    this.userRole = this.appData.getAppData('currentUser').roles[0].name;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onClearFilters() {
    this.filterBy = '';
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.refresh.emit({});
    this.videoClicked.emit(undefined);
    this.updateDataView();
  }

  updateDataView() {
    this.visible = false;
    this.videoList = [];
    setTimeout(() => (this.visible = true), 0);
  }

  isSelected(imageId) {
    return this.selectedVideoId === imageId ? '5px solid #323232' : '';
  }

  lazyLoadingVideo(event) {
    this.loading = true;

    const filterCount = [];
    const filters = [];
    // filters.push('filter[offset]=' + event.first);
    // filters.push('filter[limit]=' + (event.first + event.rows));
    filters.push('filter[where][type][regexp]=/video/');
    filterCount.push('where[type][regexp]=/video/');

    if (this.sortField !== null && this.sortOrder !== null) {
      const ordering = this.sortOrder === -1 ? ' DESC' : ' ASC';
      filters.push('filter[order]=' + this.sortField + ordering);
    }

    filters.push('filter[where][containerName]=' + this.containerName);
    filterCount.push('where[containerName]=' + this.containerName);

    filters.push('filter[where][roles][inq]=' + this.userRole);
    filterCount.push('where[roles][inq]=' + this.userRole);

    if (this.filterByFolder.length > 0 && this.filterByFolder !== 'root') {
      filters.push('filter[where][folder]=' + this.filterByFolder);
      filterCount.push('where[folder]=' + this.filterByFolder);
    }

    if (this.filterBy !== '') {
      filters.push('filter[where][or][0][fileName][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][1][title][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][2][description][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][3][folder][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][4][originalFileName][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][0][fileName][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][1][title][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][2][description][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][3][folder][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][4][originalFileName][regexp]=/' + this.filterBy + '/i');
    }

    if (this.filterByTag.length > 0) {
      this.filterByTag.forEach(tag => {
        filters.push(`filter[where][tag][inq]=${tag}`);
        filterCount.push(`where[tag][inq]=${tag}`);
      });
    }

    // this.mediaApi.getImagesCount(filterCount).subscribe((res: any) => {
    //   this.totalRecords = res.count;
    // });

    this.mediaApi.getVideos(filters).subscribe((res: any) => {
      // this.videoList = res;
      // this.videoList.forEach((video, i) => {
      //   this.videoList[i].src = this.baseUrl + video.fileName;
      // });
      this.videoList = this.filterByFolder ? res.map(this.mapVideoInFolder) : this.mapVideoFolder(res);
      this.totalRecords = this.videoList.length;
      this.videoList = this.videoList.slice(event.first);
      this.loading = false;
    });
  }

  mapVideoInFolder = (img, i) => {
    return { ...img, src: environment.mediaUrl + img.fileName };
  };

  mapVideoFolder = res => {
    const images = {};
    res.forEach(curr => {
      const key = curr.folder ? curr.folder : 'root';
      const currFold = images[key] ? images[key] : [];
      currFold.push({ folder: key });
      images[key] = currFold;
    });
    const folders = Object.keys(images).map(curr => {
      return { folder: curr };
    });
    return folders.sort((a, b) => {
      return a.folder > b.folder ? 1 : a.folder < b.folder ? -1 : 0;
    });
  };

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onVideoClick(videoId) {
    this.selectedVideoId = videoId;
    const mediaBox = { ...this.videoList.find(el => el.id === videoId) };
    this.videoClicked.emit(mediaBox);
  }

  onImageDoubleClick(image) {
    this.filterByFolder = image.folder;
    this.updateDataView();
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe((language: any) => {
      this.sysLanguage = language;
      this.sortOptions = [
        { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
        { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
        { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
        { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
      ];
      this.visible = true;
    }); */
    this.sortOptions = [
      { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
      { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
      { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
      { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
    ];
    this.tagOptions = [
      { value: '', label: '-' },
      { value: 'cruiseShip', label: this.translateService.instant('media.tag.cruiseShip') },
      { value: 'navalVessels', label: this.translateService.instant('media.tag.navalVessels') },
      { value: 'ferry', label: this.translateService.instant('media.tag.ferry') },
      { value: 'shypYards', label: this.translateService.instant('media.tag.shypYards') },
      { value: 'megaYatch', label: this.translateService.instant('media.tag.megaYatch') },
      { value: 'repair', label: this.translateService.instant('media.tag.repair') },
      { value: 'offShore', label: this.translateService.instant('media.tag.offShore') },
      { value: 'systemAndComponents', label: this.translateService.instant('media.tag.systemAndComponents') },
      { value: 'events', label: this.translateService.instant('media.tag.events') }
    ];
    this.visible = true;
  }

  showConfirm(videoId) {
    this.videoId = videoId;
    this.messageService.clear();
    this.messageService.add({
      key: 'g',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure?',
      detail: 'Confirm to proceed'
    });
  }

  onConfirm() {
    this.mediaApi.deleteMedia(this.videoId).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Delete Message', detail: 'Delete Completed' });
      setTimeout(() => {
        this.updateDataView();
      }, 1000);
    });
    this.messageService.clear('g');
  }

  onReject() {
    this.videoId = undefined;
    this.messageService.clear('g');
  }
}
