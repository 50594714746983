<div
  class="layout-wrapper"
  (click)="onLayoutClick()"
  *ngIf="currentUser"
  [ngClass]="{
    'menu-layout-static': !isOverlay(),
    'menu-layout-overlay': isOverlay(),
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-horizontal': isHorizontal(),
    'menu-layout-slim': isSlim(),
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive
  }"
>
  <shared-topbar [userID]="userID"></shared-topbar>

  <div class="layout-menu-container" (click)="onMenuClick($event)">
    <p-scrollPanel #layoutMenuScroller>
      <div class="menu-scroll-content">
        <panel-menu></panel-menu>
      </div>
    </p-scrollPanel>
  </div>

  <div class="layout-main">
    <div class="layout-main-content">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
  <div class="layout-mask"></div>
</div>
