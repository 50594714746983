import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApprovalFlowGrades, ApprovalFLowUser } from '../models/approval-flow';

@Injectable()
export class ContentManagementApi {
  constructor(protected httpClient: HttpClient) {}

  getListContentCount = (endpoint, filtersCount) =>
    this.httpClient.get(`network-content/${endpoint}/count?${filtersCount.join('&')}`);

  getListContent = (endpoint, filters = []) => {
    return this.httpClient.get<any[]>(`network-content/${endpoint}?${filters ? filters.join('&') : ''}`);
  };
  getListContentFiltered = (endpoint, filters, roles) => {
    return this.httpClient.get<any[]>(`network-content/${endpoint}?${filters ? filters.join('&') : ''}&roles=${roles}`);
  };
  getListContentFilteredCount = (endpoint, filtersCount, roles) => {
    return this.httpClient.get(`network-content/${endpoint}/count?${filtersCount.join('&')}&roles=${roles}`);
  };
  getListContentByRole = (endpoint, role, where = {}) => {
    return this.httpClient.get(`network-content/${endpoint}/${role}?filter=${JSON.stringify(where)}`);
  };
  getListContentByRoleCount = (endpoint, role, where = {}) => {
    return this.httpClient.get(`network-content/${endpoint}/${role}/count?where=${JSON.stringify(where)}`);
  };

  exportContent = (endpoint, filters) => {
    const options = { responseType: 'blob' };
    return this.httpClient.get<any[]>(
      `network-content/${endpoint}/exportAll?${filters ? filters.join('&') : ''}`,
      // @ts-ignore
      options
    );
  };

  getGeneralConfigs = () => {
    return this.httpClient.get(`network-content/general-configs`);
  };

  getGeneralConfigByKey = key => {
    return this.httpClient.get<any[]>(`network-content/general-configs?filter={"where": {"key": "${key}" }}`);
  };

  addGeneralConfig = value => {
    return this.httpClient.post(`network-content/general-configs`, value);
  };

  updateGeneralConfig = (id, value) => {
    return this.httpClient.patch(`network-content/general-configs/${id}`, value);
  };

  getContentById(endpoint, id) {
    return this.httpClient.get<{ content: any; locale: string }>('network-content/' + endpoint + '/' + id);
  }

  getPublishedContentBySlug(endpoint, slug, locale) {
    return this.httpClient.get(`network-content/${endpoint}/published/${slug}?locale=${locale}`);
  }

  deleteContentById(endpoint, id) {
    return this.httpClient.delete('network-content/' + endpoint + '/' + id);
  }

  deleteContent(endpoint, data) {
    return this.httpClient.delete('network-content/' + endpoint + '/' + data);
  }

  createNewContent(endpoint, data) {
    return this.httpClient.post('network-content/' + endpoint, data);
  }

  bulkUpdateContent(endpoint, data) {
    return this.httpClient.post(`network-content/${endpoint}/bulk-upsert`, data);
  }

  patchContent = (endpoint, id, data) => this.httpClient.patch(`network-content/${endpoint}/${id}`, data);

  updateContent(endpoint, data) {
    return this.httpClient.put('network-content/' + endpoint, data);
  }

  updateConfigEmail() {
    return this.httpClient.post('network-content/mailing-lists-config/set', null);
  }

  sendTestMail(body) {
    return this.httpClient.post('network-content/mailing-lists-jobs/test-send', body);
  }
}
