import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { Observable, fromEvent, merge, interval, Subscription } from 'rxjs';
import { switchMap, take, skipWhile, tap, debounceTime } from 'rxjs/operators';

@Injectable()
export class InactivityService {
  inactivityTime: number = 180; // 3 minutes

  subscription: Subscription;
  observableEvents$: Observable<any>;
  observableInactivityTimer$: Observable<any>;

  inactivityEvents: Array<any> = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
  ];

  constructor(public ngZone: NgZone) {
    const observables$ = [];
    this.inactivityEvents.forEach(([target, event]) => {
      observables$.push(fromEvent(target, event).pipe(debounceTime(1000)));
    });
    this.observableEvents$ = merge(...observables$);
  }
  
  createObservable(): void {
    this.ngZone.runOutsideAngular(() => {
      this.observableInactivityTimer$ = this.observableEvents$.pipe(
        switchMap(() => interval(1000).pipe(take(this.inactivityTime))),
        skipWhile(value => value != this.inactivityTime - 1)
      );
    });
  }

  subscribeObservable(cb: (args) => {}): void {
    this.subscription = this.observableInactivityTimer$.subscribe((args) => {
      cb(args);
      this.unsubscribeObservable();
    });
  }
  
  unsubscribeObservable(): void {
    this.subscription.unsubscribe();
  }
}
