import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, AfterViewChecked, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
const env = require('src/environments/environment');
import { AppDataService } from '../../../core/services/app-data.service';

@Component({
  selector: 'media-data-view',
  templateUrl: './media-data-view.component.html',
  styleUrls: ['./media-data-view.component.scss']
})
export class MediaDataViewComponent implements OnInit, AfterViewChecked {
  @Output() imageClicked = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();

  imagesList: any[];
  totalRecords: number;
  sortOptions: any[];
  sortOrder: number;
  sortField: string;
  filterBy: string;
  loading: boolean;
  baseUrl: string;
  apiKey: string;
  visible: boolean;
  selectedImageId = '';
  cmsLanguage: string;
  sysLanguage: any;
  containerName: string;
  tagOptions: any[];
  filterByTag: string[];
  folders: any[];
  filterByFolder: string;
  userRole: string;

  constructor(
    private mediaApi: MediaApi,
    private cdRef: ChangeDetectorRef,
    protected appData: AppDataService,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.containerName = env.environment.s3Bucket;

    this.baseUrl = `${env.environment.apiUrl}network-content/medias/resize/`;

    this.apiKey = '?apikey=' + env.environment.apiKey;
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.filterBy = '';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
    this.userRole = this.appData.getAppData('currentUser').roles[0].name;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onClearFilters() {
    this.filterBy = '';
    this.sortOrder = -1;
    this.sortField = 'updatedAt';
    this.selectedImageId = '';
    this.filterByTag = [];
    this.filterByFolder = '';
    this.refresh.emit({});
    this.imageClicked.emit(undefined);
    this.updateDataView();
  }

  updateDataView() {
    this.visible = false;
    this.imagesList = [];
    setTimeout(() => (this.visible = true), 0);
  }

  lazyLoadingImages(event) {
    this.loading = true;

    const filterCount = [];
    const filters = [];
    // filters.push('filter[offset]=' + event.first);
    // filters.push('filter[limit]=' + (event.first + event.rows));
    filters.push('filter[where][type][regexp]=/image/');
    filterCount.push('where[type][regexp]=/image/');

    if (this.sortField !== null && this.sortOrder !== null) {
      const ordering = this.sortOrder === -1 ? ' DESC' : ' ASC';
      filters.push('filter[order]=' + this.sortField + ordering);
    }

    filters.push('filter[where][containerName]=' + this.containerName);
    filterCount.push('where[containerName]=' + this.containerName);

    filters.push('filter[where][roles][inq]=' + this.userRole);
    filterCount.push('where[roles][inq]=' + this.userRole);

    if (this.filterByFolder.length > 0 && this.filterByFolder !== 'root') {
      filters.push('filter[where][folder]=' + this.filterByFolder);
      filterCount.push('where[folder]=' + this.filterByFolder);
    }

    if (this.filterBy !== '') {
      filters.push('filter[where][or][0][fileName][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][1][title][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][2][description][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][3][folder][regexp]=/' + this.filterBy + '/i');
      filters.push('filter[where][or][4][originalFileName][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][0][fileName][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][1][title][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][2][description][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][3][folder][regexp]=/' + this.filterBy + '/i');
      filterCount.push('where[or][4][originalFileName][regexp]=/' + this.filterBy + '/i');
    }

    if (this.filterByTag.length > 0) {
      this.filterByTag.forEach(tag => {
        filters.push(`filter[where][tag][inq]=${tag}`);
        filterCount.push(`where[tag][inq]=${tag}`);
      });
    }

    // this.mediaApi.getImagesCount(filterCount).subscribe((res: any) => {
    //   this.totalRecords = res.count;
    // });

    this.mediaApi.getImages(filters).subscribe((res: any) => {
      this.imagesList = this.filterByFolder ? res.map(this.mapImagesInFolder) : this.mapImagesFolder(res);
      this.totalRecords = this.imagesList.length;
      this.imagesList = this.imagesList.slice(event.first);
      this.loading = false;
    });
  }
  mapImagesInFolder = (img, i) => {
    return { ...img, src: this.baseUrl + img.id + '?width=200' };
  };

  mapImagesFolder = res => {
    const images = {};
    res.forEach(curr => {
      const key = curr.folder ? curr.folder : 'root';
      const currFold = images[key] ? images[key] : [];
      currFold.push({ folder: key });
      images[key] = currFold;
    });
    const folders = Object.keys(images).map(curr => {
      return { folder: curr };
    });
    return folders.sort((a, b) => {
      return a.folder > b.folder ? 1 : a.folder < b.folder ? -1 : 0;
    });
  };

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  onImageClick(imageId) {
    this.selectedImageId = imageId;
    this.imageClicked.emit(this.imagesList.find(el => el.id === imageId));
  }

  onImageDoubleClick(image) {
    this.filterByFolder = image.folder;
    this.updateDataView();
  }

  isSelected(imageId) {
    return this.selectedImageId === imageId ? '5px solid #323232' : '';
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe((language: any) => {
      this.sysLanguage = language;
      this.sortOptions = [
        { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
        { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
        { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
        { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
      ];
      this.visible = true;
    }); */

    this.sortOptions = [
      { label: this.translateService.instant('common.filters.newestFirst'), value: '!updatedAt' },
      { label: this.translateService.instant('common.filters.oldestFirst'), value: 'updatedAt' },
      { label: this.translateService.instant('common.filters.filenameAZ'), value: 'fileName' },
      { label: this.translateService.instant('common.filters.filenameZA'), value: '!fileName' }
    ];

    this.tagOptions = [
      { value: '', label: '-' },
      { value: 'cruiseShip', label: this.translateService.instant('media.tag.cruiseShip') },
      { value: 'navalVessels', label: this.translateService.instant('media.tag.navalVessels') },
      { value: 'ferry', label: this.translateService.instant('media.tag.ferry') },
      { value: 'shypYards', label: this.translateService.instant('media.tag.shypYards') },
      { value: 'megaYatch', label: this.translateService.instant('media.tag.megaYatch') },
      { value: 'repair', label: this.translateService.instant('media.tag.repair') },
      { value: 'offShore', label: this.translateService.instant('media.tag.offShore') },
      { value: 'systemAndComponents', label: this.translateService.instant('media.tag.systemAndComponents') },
      { value: 'events', label: this.translateService.instant('media.tag.events') }
    ];
    this.visible = true;
  }
}
