import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppData } from '../models/app-data.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppDataService {
  private appData: AppData;
  public cmsLanguage$ = new BehaviorSubject<string>(null);

  constructor(protected translateService: TranslateService) {
    this.appData = {
      currentUser: null,
      cmsLanguage: null,
      locale: null,
      token: null,
      routerData: null
    };
  }

  init() {
    const currentCmsLanguage = this.appData.cmsLanguage;
    // Parse current user
    this.appData.currentUser = JSON.parse(localStorage.getItem('currentUser')) || null;
    // Parse token
    this.appData.token = localStorage.getItem('token') || null;
    // Parse locale or set default
    const locale = localStorage.getItem('locale');
    if (locale) {
      this.appData.locale = locale;
    } else {
      this.appData.locale = 'it';
    }
    this.setAppData('locale', this.appData.locale);
    // Parse cmsLanguage or set default
    const cmsLanguage = localStorage.getItem('cmsLanguage');
    if (this.appData.currentUser && this.appData.currentUser.user.preferredLanguage) {
      this.appData.cmsLanguage = this.appData.currentUser.user.preferredLanguage;
    } else {
      if (cmsLanguage) {
        this.appData.cmsLanguage = cmsLanguage;
      } else {
        this.appData.cmsLanguage = 'en';
      }
    }
    this.setAppData('cmsLanguage', this.appData.cmsLanguage);
    // Check if update translations is needed
    if (currentCmsLanguage !== this.appData.cmsLanguage) {
      this.cmsLanguage$.next(this.appData.cmsLanguage);
    }
    // Parse routerData or set default
    const routerData = localStorage.getItem('routerData');
    if (routerData) {
      this.appData.routerData = routerData.split(',');
    } else {
      this.appData.routerData = [];
    }
    this.setAppData('routerData', this.appData.routerData);

    // this.translateService.setDefaultLang(this.appData.cmsLanguage);
  }

  setAppData(key, value) {
    this.appData[key] = value;
    localStorage.setItem(key, value);
  }

  getAppData(key) {
    this.init();
    return this.appData ? this.appData[key] : null;
  }

  emptyAppData() {
    this.appData = {
      currentUser: null,
      token: null,
      cmsLanguage: null,
      locale: null,
      routerData: null
    };
    this.init();
  }
}
