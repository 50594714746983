import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LaBussolaFeedApi {
  constructor(protected httpClient: HttpClient) {}

  getFeedFromLaBussolaServerAndPersist() {
    return this.httpClient.post('network-content/la-bussola-feed/la-bussola-feed',{});
  }
}