import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenuApi {
  constructor(protected httpClient: HttpClient) {}

  getMenuItems() {
    return this.httpClient.get('auth/bo-menu?filter={"order":"sort ASC","include":[{"relation":"permissions"}]}');
  }

  getMenuItemById(id: string) {
    return this.httpClient.get('auth/bo-menu/' + id);
  }

  createMenuItem(data: any) {
    return this.httpClient.post('auth/bo-menu/', data);
  }

  updateMenuItem(id: string, data: any) {
    return this.httpClient.put('auth/bo-menu/' + id, data);
  }

  deleteMenuItem(id: string) {
    return this.httpClient.delete('auth/bo-menu/' + id);
  }
}
