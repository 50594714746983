<div class="topbar clearfix">
  <div class="topbar-left">
    <a class="logo" routerLink="/back-office" style="cursor: pointer; outline: none;" tabindex="0">
      <img alt="Logo" src="assets/layout/images/newlogo.png" />
    </a>
  </div>
  <div class="topbar-right">
    <a href="#" id="menu-button" (click)="onMenuButtonClick($event)"><i class="fa fa-angle-left"></i></a>
    <ul class="topbar-items fadeInDown animated">
      <li class="profile-item dropdown" *ngIf="user !== undefined">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
          aria-expanded="false"
          style="
          display: flex;
          align-items: center;
          align-content: center;">
          <div class="user-container">
            <img class="profile-image" src="assets/layout/images/avatar-generic.png" />
            <span *ngIf="notificationsCount" class="blob"></span>
          </div>
          <span class="topbar-item-name">
            {{ user.firstName | titlecase }} {{ user.lastName | titlecase }}<small>{{ userRole | uppercase }}</small>
          </span>
        </a>
        <ul class="poseidon-menu fadeInDown dropdown-menu custom-menu">
          <li role="menuitem">
            <a routerLink="user/profile" (click)="onTopbarSubItemClick($event)">
              <i class="fa fa-fw fa-user"></i>
              <span>{{ 'user.menu.profile' | translate | titlecase }}</span>
            </a>
          </li>
          <li role="menuitem">
            <a routerLink="user/notifications" (click)="onTopbarSubItemClick($event)">
              <i class="fa fa-fw fa-bell"></i><span>{{ 'user.menu.notifications' | translate | titlecase }}</span>
              <span *ngIf="notificationsCount" class="circle">{{notificationsCount}}</span>
            </a>
          </li>
          <li role="menuitem" *ngIf="isAdmin">
            <a routerLink="user/system" (click)="onTopbarSubItemClick($event)">
              <i class="fa fa-fw fa-cog"></i><span>{{ 'user.menu.manage' | translate | titlecase }}</span>
            </a>
          </li>
          <li role="menuitem">
            <a href="#" (click)="logout($event)">
              <i class="fa fa-fw fa-sign-out"></i><span>{{ 'user.menu.logout' | translate | titlecase }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
