import { CommonApi } from './../../../core/apis/common.api';
import { AuthService } from './../../../core/services/auth.service';
import { BackOfficeMainComponent } from './../../../back-office/components/back-office-main/back-office-main.component';

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
const env = require('src/environments/environment');
import { AppDataService } from 'src/app/core/services/app-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'shared-topbar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  @Input() userID;

  // ---

  rotateMenuButton;
  profileMode;
  activeTopbarItem;
  topbarMenuActive;
  topbarItemClick;
  cachedLanguage;
  sysLanguage = {};
  visible: boolean;
  isAdmin: boolean;
  projectName = env.environment.formattedName;
  userRole: string;
  user: any;
  notificationsCount: number;

  // ---

  constructor(
    protected backOffice: BackOfficeMainComponent,
    protected authService: AuthService,
    protected commonAPI: CommonApi,
    protected appData: AppDataService,
    protected notificationService: NotificationService
  ) {
  }

  // ---

  ngOnInit() {
    this.notificationService.count.subscribe((value) => {
      this.notificationsCount = value
  });
    const userId = this.appData.getAppData('currentUser').userId;
    this.rotateMenuButton = this.backOffice.rotateMenuButton;
    this.profileMode = this.backOffice.profileMode;
    this.activeTopbarItem = this.backOffice.activeTopbarItem;
    this.topbarMenuActive = this.backOffice.topbarMenuActive;
    this.topbarItemClick = this.backOffice.topbarItemClick;
    this.getUser(userId);
    this.getUserRole(userId);
  }

  // ---

  getUser = userID => {
    this.commonAPI.getUser(userID).subscribe((user: any) => {
      this.user = user;
    });
  };

  getUserRole = userID => {
    this.commonAPI.getUserRole(userID).subscribe((role: any) => {
      this.userRole = role[0].name;
      if (role[0].name === 'ADMIN') {
        this.isAdmin = true;
        return;
      }
      return (this.isAdmin = false);
    });
  };

  logout = event => {
    // event.preventDefault();
    // event.stopImmediatePropagation();
    localStorage.removeItem('code');
    this.authService.logout();
  };

  // ---

  onMenuButtonClick = e => {
    this.rotateMenuButton = !this.rotateMenuButton;
    this.backOffice.onMenuButtonClick(e);
  };

  onTopbarMenuButtonClick = e => {
    this.backOffice.onTopbarMenuButtonClick(e);
  };

  onTopbarItemClick = (e, p) => {
    this.topbarItemClick = true;
    if (this.activeTopbarItem === p) {
      this.activeTopbarItem = null;
    } else {
      // @TODO se serve cambiare la lingua del TranslateService
      // this.cachedLanguage = this.appData.getAppData('cmsLanguage');
      // this.loadLanguage(this.cachedLanguage);
      this.activeTopbarItem = p;
    }
    e.preventDefault();
  };

  onTopbarSubItemClick = e => {
    if (this.activeTopbarItem !== null) {
      this.activeTopbarItem = null;
    }
    this.backOffice.onTopbarSubItemClick(e);
  };

  // ---

  isHorizontal = () => {
    this.backOffice.isHorizontal();
  };
}
