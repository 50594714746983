import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MenuService } from 'src/app/core/services/menu.service';
import { PageWithSectionTitleComponent } from 'src/app/shared/components/page-with-section-title/page-with-section-title.component';
import { AppDataService } from '../../../core/services/app-data.service';
const env = require('src/environments/environment');

@Component({
  selector: 'media-video',
  templateUrl: './media-video.component.html',
  styleUrls: ['./media-video.component.scss']
})
export class MediaVideoComponent extends PageWithSectionTitleComponent implements OnInit {
  @Input() selectVideoEnabled: boolean;
  @Output() videoClicked = new EventEmitter<any>();

  videoData: any;
  cmsLanguage: string;
  sysLanguage: any;
  visible: boolean;

  constructor(protected appData: AppDataService, protected menuService: MenuService) {
    super(menuService);
  }

  ngOnInit(): void {
    this.cmsLanguage = this.appData.getAppData('cmsLanguage');
    this.loadLanguage(this.cmsLanguage);
  }

  onSelectVideo() {
    if (this.selectVideoEnabled && this.videoData !== undefined) {
      //const url = encodeURI(`${env.environment.apiUrl}network-content/medias/video/${this.videoData}`);
      this.videoClicked.emit(this.videoData);
    }
  }

  loadLanguage(lang) {
    /* this.languageService.getLanguage(lang).subscribe(language => {
      this.sysLanguage = language;
      this.visible = true;
    }); */
    this.visible = true;
  }
}
