import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApprovalFlowApi } from '../apis/approval-flow.api';
import { AppDataService } from './app-data.service';

export interface SendAndApproveParams{
  slug: string;
  locale: string;
  isSkipToast?: boolean;
}
export interface OnApproveParams{
  id: string;
  isSkipToast?: boolean;
}
export interface OnRejectParams{
  itemToReject: string;
  rejectNotes: string;
  isSkipToast?: boolean;
}
export interface OnResetParams{
  id: string;
  isSkipToast?: boolean;
}


@Injectable()
export class ApprovalFlowService {
  private userData$: ReplaySubject<any>;
  private isAdminOrTech: boolean;
  private isEditor: boolean;
  private currentUserRole: string;
  private grade: string;

  constructor(
    protected appDataService: AppDataService,
    protected approvalFlowApi: ApprovalFlowApi,
    protected messageService: MessageService
  ) {
    this.currentUserRole = this.appDataService.getAppData('currentUser').roles[0].name;
    this.isAdminOrTech = this.currentUserRole === 'ADMIN' || this.currentUserRole === 'TECH';
    this.isEditor = this.currentUserRole === 'EDITOR';

    this.userData$ = new ReplaySubject<any>(null);
    this.approvalFlowApi.getUserGradeAndRole().subscribe(userData => {
      this.grade = userData.grade;
      this.userData$.next(userData);
    });
  }

  async getUserAuthorization(templateSlug: string, templateRoles: Array<string> = [], status: string) {
    try {
      if (this.isAdminOrTech) {
        return {};
      }

      const isContributor = this.grade === 'CONTRIBUTOR';

      const isPending = status === 'pending';
      const isApproved = status === 'approved';

      const templateApprovers = await lastValueFrom(this.approvalFlowApi.getTemplateApprovers(templateSlug));
      const hasTemplateApprovers = !!(
        templateApprovers &&
        templateApprovers.approvers &&
        templateApprovers.approvers.length
      );
      const templateApproversRole = templateApprovers && templateApprovers.role;
      const approversRoleInTemplateRole = !!templateRoles.find(
        // @ts-ignore
        templateRole => templateRole.role === templateApproversRole
      );
      const needsApprovalFromTemplate =
        hasTemplateApprovers && !approversRoleInTemplateRole && this.currentUserRole !== templateApproversRole;

      const needsApproval = !isApproved && (isPending || needsApprovalFromTemplate || isContributor);

      return {
        status: status || (needsApproval ? 'Needs approval' : ''),
        needsApproval
      };
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  isAdmin() {
    return this.userData$.pipe(map(userData => this.isAdminOrTech || userData.grade === 'ADMIN'));
  }

  isApprover() {
    return this.userData$.pipe(map(userData => this.isAdminOrTech || userData.grade === 'APPROVER'));
  }

  isContributor() {
    return this.userData$.pipe(map(userData => !this.isAdminOrTech && userData.grade === 'CONTRIBUTOR' && !this.isEditor));
  }

  // Workaround to avoid approvation
  //async onSendToApprover(slug, locale) {
  async onSendAndApprove(params : SendAndApproveParams) {
    try {
      await lastValueFrom(this.approvalFlowApi.sendToApprover(params.slug, params.locale));
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'success',
          summary: 'DEM sent and approved'
        });
      }
    } catch (_) {
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'error',
          summary: 'Error while sending DEM for approval'
        });
      }
    }
  }

  async onApprove(params : OnApproveParams) {
    try {
      await lastValueFrom(this.approvalFlowApi.approveDEM(params.id));
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'success',
          summary: 'DEM approved'
        });
      }
    } catch (_) {
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'error',
          summary: 'Error while approving DEM'
        });
      }
    }
  }

  async onReject(params : OnRejectParams ) {
    try {
      await lastValueFrom(this.approvalFlowApi.rejectDEM(params.itemToReject, params.rejectNotes));
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'success',
          summary: 'DEM rejected'
        });
      }
    } catch (_) {
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'error',
          summary: 'Error while rejecting DEM'
        });
      }
    }
  }

  async onResetStatus(params : OnResetParams) {
    try {
      await lastValueFrom(this.approvalFlowApi.resetStatus(params.id));
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'success',
          summary: 'DEM reset'
        });
      }
    } catch (_) {
      if(!params.isSkipToast){
        this.messageService.add({
          severity: 'error',
          summary: 'Error while resetting DEM'
        });
      }
    }
  }
}
