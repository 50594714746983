<div class="resetPasswordComponent">
  <div class="container">
    <div class="row">
      <div class="col-sm-offset-1 col-sm-10">
        <p>{{ 'resetPassword.description' | translate }}</p>
        <label>{{ 'common.labels.email' | translate }}</label>
        &nbsp;
        <input type="email" [(ngModel)]="email" />
        <br /><br />
        <button class="btn btn-primary pull-left" (click)="resetPassword()">
          {{ 'resetPassword.actions.reset' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
