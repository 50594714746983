// @ts-nocheck
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { MediaApi } from '../../../core/apis/media.api';
import { ContentManagementApi } from '../../../core/apis/content-management-api.service';
import { SlugService } from '../../../core/services/slug.service';
import { AppDataService } from '../../../core/services/app-data.service';
import { environment } from 'src/environments/environment';
import { JSONEditor } from '@json-editor/json-editor';
import { MenuService } from 'src/app/core/services/menu.service';
import { CommonApi } from 'src/app/core/apis/common.api';
import { lastValueFrom } from 'rxjs';

import flatpickr from 'flatpickr';
import $ from 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'summernote/dist/summernote-bs4.min.js';

@Component({
  selector: 'json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss'],
})
export class JsonEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jsonValues = {};
  @Input() jsonSchema: any = {};
  @Input() selectedLanguage: any;
  @Input() contentType: string;
  @Input() contentPending: boolean;
  @Input() contentApproved: boolean;
  @Input() editorAlwaysEnabled: boolean;
  @Input() canEditAfterApproval: boolean;

  @Output() valuesToSave = new EventEmitter();
  @Output() addImage = new EventEmitter();
  @Output() addPdf = new EventEmitter();
  @Output() addIcs = new EventEmitter();
  @Output() addVideo = new EventEmitter();
  @Output() editorObj = new EventEmitter();

  @Output() templateChange = new EventEmitter();

  editorId: string = 'cmsEditor';

  tempValues: any;

  editor: any;
  imageFieldSelected: any;

  contentsByMenu = { enum: [], labels: [] };
  pressMailTemplates = {
    enum: [],
    labels: [],
    colorSet: [],
    fontSet: [],
    linkSet: [],
    templatesContent: [],
  };

  jsonEditorElement: HTMLElement;

  protected options = {
    ajax: true,
    compact: true,
    array_controls_top: false,
    disable_array_add: false,
    disable_array_delete: false,
    disable_array_reorder: false,
    disable_array_delete_last_row: true,
    disable_array_delete_all_rows: true,
    disable_collapse: true,
    disable_edit_json: true,
    disable_properties: true,
    display_required_only: false,
    expand_height: true,
    form_name_root: 'root',
    iconlib: 'fontawesome5',
    keep_oneof_values: false,
    no_additional_properties: true,
    object_layout: 'normal',
    prompt_before_delete: true,
    refs: {},
    required_by_default: true,
    schema: {},
    show_errors: 'never',
    startval: null,
    template: 'default',
    theme: 'bootstrap4',
  };

  constructor(
    protected mediaApi: MediaApi,
    protected contentManagementApi: ContentManagementApi,
    protected appData: AppDataService,
    protected cdr: ChangeDetectorRef,
    protected slugService: SlugService,
    private menuService: MenuService,
    protected commonApi: CommonApi
  ) {}

  ngOnInit() {
    this.loadDataBeforeJsonEditorInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedLanguage &&
      changes.selectedLanguage.previousValue &&
      changes.selectedLanguage.previousValue !==
        changes.selectedLanguage.currentValue
    ) {
      this.clearEditors();
      this.jsonValues = this.tempValues;
      this.loadDataBeforeJsonEditorInit();
    }
    if (
      changes.jsonSchema &&
      changes.jsonSchema.previousValue &&
      changes.jsonSchema.previousValue.$ref &&
      changes.jsonSchema.currentValue.$ref &&
      changes.jsonSchema.previousValue.$ref !==
        changes.jsonSchema.currentValue.$ref
    ) {
      this.clearEditors();
      this.jsonValues = this.tempValues;
      this.loadDataBeforeJsonEditorInit();
    }
  }

  async loadDataBeforeJsonEditorInit() {
    if (Object.keys(this.jsonSchema).length > 0) {
      const user = this.appData.getAppData('currentUser');
      switch (this.jsonSchema.$ref) {
        case '../../../../../assets/schemas/mail/mail-templates.json':
          const menuContents = await this.menuService.getSectionsByEndpoint(
            'press-content'
          );
          this.contentsByMenu.enum = menuContents.map(
            menuItem => menuItem.data.type
          );
          this.contentsByMenu.labels = menuContents.map(
            menuItem => menuItem.label
          );
          break;
        case '../../../../../assets/schemas/press/press-content.json':
        case '../../../../../assets/schemas/press/press-reviews-gmi.json':
          const [_, typeKey] = this.appData.getAppData('routerData');
          const res = await lastValueFrom(
            this.contentManagementApi.getListContent(
              'mail-templates/published',
              [`locale=${this.selectedLanguage}`]
            )
          );
          this.pressMailTemplates.templatesContent = res;
          const role = user.roles[0].name;
          const resFilter = res.filter(curr => {
            const listRoleName = curr.content.templateRoles
              ? curr.content.templateRoles.map(n => n.role)
              : [];
            return (
              curr.content.templateType === typeKey &&
              listRoleName.includes(role)
            );
          });
          this.pressMailTemplates.enum = resFilter.map(template => {
            return template.slug;
          });
          this.pressMailTemplates.labels = resFilter.map(template => {
            return template.title;
          });
          this.pressMailTemplates.colorSet = resFilter.reduce(
            (
              acc,

              { slug, content: { general_settings: { colors } = {} } }
            ) => ({
              ...acc,
              [slug]: colors ? colors.map(({ color }) => color) : [],
            }),
            {}
          );
          this.pressMailTemplates.fontSet = resFilter.reduce(
            (acc, { slug, content: { general_settings: { fonts } = {} } }) => ({
              ...acc,
              [slug]: fonts ? fonts.map(({ font }) => font) : [],
            }),
            {}
          );

          this.pressMailTemplates.linkSet = resFilter.reduce(
            (acc, { slug, content: { link_press_complete } }) => ({
              ...acc,
              [slug]:
                link_press_complete && link_press_complete.links
                  ? link_press_complete.links
                  : [],
            }),
            {}
          );
          break;
        default:
          break;
      }
      this.jsonEditorInit();
    }
  }

  clearEditors() {
    this.cdr.detectChanges();
    const element = document.getElementById(this.editorId);
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  jsonEditorInit() {
    const values = this.jsonValues;
    const options = this.options;
    options.schema = this.jsonSchema;
    const mailTemplates = this.pressMailTemplates;

    const self = this;

    JSONEditor.defaults.editors.wysiwyg = class MySummernote extends JSONEditor
      .defaults.editors.string {
      postBuild() {
        super.postBuild();

        if ($?.fn?.summernote) {
          const textEditor = this;
          const input = $(this.input);

          this.summernote_instance = input.summernote({
            width: '75%',
            height: 100,
            disableDragAndDrop: true,
            followingToolbar: false,
            styleTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            fontNames: [
              'Arial',
              'Arial Black',
              'Arial Regular',
              'Calibri',
              'Courier New',
              'DIN Regular',
              'Helvetica Neue',
              'Helvetica',
              'Impact',
              'Lucida Grande',
              'Tahoma',
              'Times New Roman',
              'Trade Gothic Condensed',
              'Trade Gothic Bold Condensed',
              'Verdana',
            ],
            fontNamesIgnoreCheck: [
              'DIN Regular',
              'Calibri',
              'Trade Gothic Condensed',
              'Trade Gothic Bold Condensed',
              'Arial Regular',
            ],
            popover: {
              image: [
                [
                  'image',
                  ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone'],
                ],
                ['float', ['floatLeft', 'floatRight', 'floatNone']],
                ['remove', ['removeMedia']],
              ],
              link: [],
              air: [],
            },
            toolbar: [
              ['style', ['style']],
              ['font', ['bold', 'underline', 'clear', 'italic']],
              ['fontname', ['fontname']],
              ['fontsize', ['fontsize']],
              ['fontsizeunit', ['fontsizeunit']],
              ['para', ['ul', 'ol', 'paragraph', 'height']],
              ['link', ['link']],
              ['color', ['color']],
              ['forecolor', ['forecolor']],
              ['backcolor', ['backcolor']],
              ['superscript', ['superscript']],
              ['subscript', ['subscript']],
              ['misc', ['undo', 'redo']],
              ['table', ['table']],
              ['insert', ['link', 'picture', 'video', 'hr']],
              ['view', ['fullscreen', 'codeview']],
            ],
            callbacks: {
              onBlur: function(e) {
                const value = input.summernote('code');
                textEditor.setValue(value);
              },
            },
          });
        }
      }
      setValue(value, initial, from_template) {
        if (this.template && !from_template) {
          return;
        }

        if (value === null || typeof value === 'undefined') value = '';
        else if (typeof value === 'object') value = JSON.stringify(value);
        else if (typeof value !== 'string') value = '' + value;

        if (value === this.serialized) return;

        // Sanitize value before setting it
        var sanitized = this.sanitize(value);
        if (this.input.value === sanitized) {
          return;
        }
        this.input.value = sanitized;
        this.summernote_instance.summernote('code', sanitized);

        var changed = from_template || this.getValue() !== value;

        this.refreshValue();

        if (initial) this.is_dirty = false;
        else if (this.jsoneditor.options.show_errors === 'change')
          this.is_dirty = true;

        if (this.adjust_height) this.adjust_height(this.input);

        // Bubble this setValue to parents if the value changed
        this.onChange(changed);
      }
      afterInputReady() {
        if ($?.fn?.summernote) {
          const input = $(this.input);

          let defaultFont;
          const TEMPLATE_PATH = 'root.mail_template';
          const templateEditor = editor.getEditor(TEMPLATE_PATH);
          if (templateEditor) {
            const template = templateEditor.getValue();
            defaultFont =
              self.pressMailTemplates.fontSet[template] &&
              self.pressMailTemplates.fontSet[template][0];
          }

          input.summernote('fontName', defaultFont || 'DIN Regular');
          input.summernote('fontSize', 14);
          input.next().css({ 'margin-left': '20%', top: '-20px' });
        }

        this.theme.afterInputReady(this.input);
      }
      destroy() {
        this.summernote_instance.summernote('destroy');

        this.template = null;

        if (this.input && this.input.parentNode)
          this.input.parentNode.removeChild(this.input);

        if (this.label && this.label.parentNode)
          this.label.parentNode.removeChild(this.label);

        if (this.description && this.description.parentNode)
          this.description.parentNode.removeChild(this.description);

        super.destroy();
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.format === 'html') {
        return 'wysiwyg';
      }
    });

    JSONEditor.defaults.editors.defaultImage = class DefaultImage extends JSONEditor
      .defaults.editors.string {
      build() {
        super.build();
        const templatePath = 'root.mail_template';
        const mainDiv = document.createElement('div');
        mainDiv.setAttribute('class', 'form-group');
        mainDiv.setAttribute('style', 'display: flex');
        const image = document.createElement('img');
        const imageLabePlaceholder = document.createElement('label');

        image.setAttribute('id', `image-${this.key}`);
        image.style.maxHeight = '200px';
        image.style.maxWidth = '200px';
        image.style.marginLeft = '5px';
        mainDiv.appendChild(image);
        mainDiv.appendChild(imageLabePlaceholder);

        this.container.appendChild(mainDiv);

        const splittedPath = this.path.split('.');
        const childKey = splittedPath[splittedPath.length - 1];
        const parentPath = splittedPath
          .slice(0, splittedPath.length - 1)
          .join('.');

        editor.watch(templatePath, () => {
          const templateSlug = editor.getEditor(templatePath).getValue();
          const template = mailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );

          if (template) {
            const imageID = template.content[this.key].id;
            if (imageID) {
              const src = `${environment.apiUrl}network-content/medias/resize/${imageID}?width=200`;
              image.setAttribute('src', src);
              imageLabePlaceholder.innerHTML = '';
            } else {
              image.removeAttribute('src');
              imageLabePlaceholder.innerHTML = 'No default image present';
              imageLabePlaceholder.setAttribute('style', 'color: red');
            }

            const updateImage = () => {
              const imgDefault = editor.getEditor(parentPath);
              if (imgDefault) {
                const oldImg = imgDefault.getValue();
                if (oldImg[childKey] !== imageID) {
                  imgDefault.setValue({ [childKey]: imageID });
                }
              }
            };

            if (editor.ready) {
              updateImage();
            } else {
              editor.on('ready', updateImage);
            }
          }
        });
      }
    };

    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'defaultImage') {
        return 'defaultImage';
      }
    });

    JSONEditor.defaults.editors.image = class Image extends JSONEditor.defaults
      .editors.string {
      build() {
        super.build();
        const imageInput = this;

        const image = document.createElement('img');
        image.setAttribute('src', '');

        image.setAttribute('id', 'image-' + imageInput.formname);
        image.style.maxHeight = '200px';
        image.style.maxWidth = '200px';
        image.style.marginLeft = '5px';

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Select Image';
        selectButton.setAttribute('class', 'btn btn-primary');
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          self.addImage.emit(imageInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset Image';
        resetButton.setAttribute('class', 'btn p-button-secondary');
        resetButton.setAttribute('disabled', 'true');
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          const imageField = editor.getEditor(imageInput.path);
          const imageId = editor.getEditor(
            imageInput.path.replace('.fileName', '.id')
          );
          if (imageField) {
            imageField.setValue('');
            imageId.setValue('');
            image.setAttribute('src', '');
          }
        });

        editor.watch(imageInput.path, () => {
          if (
            editor.getEditor(imageInput.path) &&
            editor.getEditor(imageInput.path).value
          ) {
            const imageId = editor.getEditor(
              imageInput.path.replace('.fileName', '.id')
            ).value;
            const src = `${environment.apiUrl}network-content/medias/resize/${imageId}?width=200`;
            image.setAttribute('src', src);
            resetButton.removeAttribute('disabled');
          } else {
            resetButton.setAttribute('disabled', 'true');
          }
        });

        const mainDiv = document.createElement('div');
        mainDiv.setAttribute('class', 'form-group');
        mainDiv.setAttribute('style', 'display: flex');
        mainDiv.appendChild(selectButton);
        mainDiv.appendChild(resetButton);
        mainDiv.appendChild(image);

        this.container.appendChild(mainDiv);
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsImage') {
        return 'image';
      }
    });

    JSONEditor.defaults.editors.sortableComponent = class SortableComponent extends JSONEditor.defaults.editors.object {
      constructor(container, schema, options) {
        super(container, schema, options);
        this.indexContent = null;
        this.mainDiv = null;
        this.title = null;
      }

      build() {
        super.build();  // Chiama il metodo build della classe genitore

        // Creazione degli elementi DOM
        const mainDiv = document.createElement('div');
        const indexContent = document.createElement('span');

        // Imposta l'id dell'elemento
        if (this.key) {
          indexContent.setAttribute('id', this.key);
        }

        // Assegna una classe al contenitore
        mainDiv.setAttribute('class', 'sortable-index');

        // Aggiungi l'indexContent a mainDiv
        mainDiv.appendChild(indexContent);

        // Trova il titolo (h3) e aggiungi il nuovo div all'inizio
        const title = this.container.getElementsByTagName('h3')[0];
        if (title) {
          title.prepend(mainDiv);
        }

        // Salva i riferimenti agli elementi DOM
        this.indexContent = indexContent;
        this.mainDiv = mainDiv;
        this.title = title;
      }

      postBuild() {
        super.postBuild();  // Chiama il metodo postBuild della classe genitore

        // Verifica che editor sia definito
        const editor = this.editor || window.editor;  // Assicurati che editor sia definito
        if (!editor) return;  // Se editor non è definito, esci

        editor.watch(this.path, () => {
          const editorEntity = editor.getEditor(this.path);

          // Verifica che l'entità sia valida
          if (!editorEntity || !editorEntity.value) return;

          // Controlla se sortValue è definito e numerico
          const sortValueHasValue = editorEntity.value.sortValue !== undefined && typeof editorEntity.value.sortValue === 'number';

          if (sortValueHasValue) {
            // Aggiorna l'indice e stile
            this.indexContent.textContent = editorEntity.value.sortValue;
            this.mainDiv.style.flex = 1;
            this.title.style.display = 'flex';
            this.title.style['justify-content'] = 'space-between';
            this.title.style['align-items'] = 'center';
          } else {
            // Nascondi l'indice se sortValue non è valido
            this.indexContent.textContent = '';
            this.title.style.display = 'inline-block';
            this.title.style['justify-content'] = '';
            this.title.style['align-items'] = '';
          }

          // Se l'oggetto non è visibile, nascondi l'indice
          if (editorEntity.value.visible === false) {
            this.indexContent.textContent = '';
            editorEntity.value.sortValue = undefined;
            this.title.style.display = 'inline-block';
            this.title.style['justify-content'] = '';
            this.title.style['align-items'] = '';
          }
        });
      }
    };

    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'sortable') {
        return 'sortableComponent';
      }
    });

    JSONEditor.defaults.editors.customColorPick = class CustomColorPick extends JSONEditor
      .defaults.editors.select {
      build() {
        super.build();
        const colorSpan = document.createElement('div');
        colorSpan.setAttribute('class', 'color-indicator');

        this.control.appendChild(colorSpan);

        this.control.setAttribute('class', 'color-pick-row');
        this.control.setAttribute('class', 'form-group');

        this.colorSpan = colorSpan;
      }
      postBuild(e) {
        super.postBuild();

        editor.watch(this.path, () => {
          const editorEntity = editor.getEditor(this.path);
          if (editorEntity) {
            this.colorSpan.setAttribute(
              'style',
              `background: ${editorEntity.value}`
            );
          }
        });
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'customColorPick') {
        return 'customColorPick';
      }
    });

    JSONEditor.defaults.editors.pressContents = class PressContents extends JSONEditor
      .defaults.editors.selectize {
      preBuild() {
        this.schema['enum'] = self.contentsByMenu.enum;

        this.schema['options']['enum_titles'] = self.contentsByMenu.labels;
        super.preBuild();
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'pressContents') {
        return 'pressContents';
      }
    });

    JSONEditor.defaults.editors.pressMailTemplates = class PressMailTemplates extends JSONEditor
      .defaults.editors.selectize {
      preBuild() {
        this.schema['enum'] = self.pressMailTemplates.enum;

        this.schema['options']['enum_titles'] = self.pressMailTemplates.labels;
        super.preBuild();
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (
        schema.type === 'string' &&
        schema.extension === 'pressMailTemplates'
      ) {
        return 'pressMailTemplates';
      }
    });

    JSONEditor.defaults.editors.pressConfig = class PressConfig extends JSONEditor
      .defaults.editors.array {
      postBuild() {
        super.postBuild();

        const TEMPLATE_PATH = 'root.mail_template';
        const templateEditor = editor.getEditor(TEMPLATE_PATH);

        const setTemplateConfig = () => {
          const template = templateEditor.getValue();

          this.setValue(self.pressMailTemplates[this.key][template]);
        };

        editor.on('ready', setTemplateConfig);
        editor.watch(TEMPLATE_PATH, setTemplateConfig);
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.extension === 'pressConfig') {
        return 'pressConfig';
      }
    });

    JSONEditor.defaults.editors.block = class Block extends JSONEditor.defaults
      .editors.object {
      postBuild() {
        super.postBuild();

        const TEMPLATE_PATH = 'root.mail_template';
        editor.watch(TEMPLATE_PATH, () => {
          const templateSlug = editor.getEditor(TEMPLATE_PATH).getValue();
          const currentTemplate = self.pressMailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );
          const templateBlock =
            currentTemplate &&
            currentTemplate.content &&
            currentTemplate.content[this.key];
          if (templateBlock) {
            this.container.style.display = templateBlock.visible ? '' : 'none';
          }
        });
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'block') {
        return 'block';
      }
    });

    JSONEditor.defaults.editors.social = class Social extends JSONEditor
      .defaults.editors.object {
      postBuild() {
        super.postBuild();

        const TEMPLATE_PATH = 'root.mail_template';
        editor.watch(TEMPLATE_PATH, () => {
          const templateSlug = editor.getEditor(TEMPLATE_PATH).getValue();
          const currentTemplate = self.pressMailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );

          const socialsBlock =
            currentTemplate &&
            currentTemplate.content &&
            currentTemplate.content.social_section &&
            currentTemplate.content.social_section.social;

          const currentSocial = socialsBlock && socialsBlock[this.key];

          if (currentSocial) {
            this.container.style.display = currentSocial.visible ? '' : 'none';
          }
        });
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'social') {
        return 'social';
      }
    });

    JSONEditor.defaults.editors.customTitle = class CustomTitle extends JSONEditor
      .defaults.editors.object {
      preBuild() {
        super.preBuild();
      }
      postBuild() {
        super.postBuild();

        const path = this.path;
        const splittedPath = path.split('.');
        splittedPath[splittedPath.length - 1] = 'override';

        const overridePath = splittedPath.join('.');
        editor.watch(overridePath, () => {
          const editorEntity = editor.getEditor(overridePath);

          if (editorEntity && this.container) {
            const override = editorEntity.value;

            this.container.style.display = override ? '' : 'none';
          }
        });

        const TEMPLATE_PATH = 'root.mail_template';

        editor.watch(TEMPLATE_PATH, () => {
          const templateSlug = editor.getEditor(TEMPLATE_PATH).getValue();
          const currentTemplate = self.pressMailTemplates.templatesContent.find(
            mailTemplate => mailTemplate.slug === templateSlug
          );

          const globalStyle =
            currentTemplate &&
            currentTemplate.content &&
            currentTemplate.content.sections &&
            currentTemplate.content.sections.globalStyle;

          this.setValue(globalStyle);
        });
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'customTitle') {
        return 'customTitle';
      }
    });

    JSONEditor.defaults.editors.customDate = class CustomDate extends JSONEditor
      .defaults.editors.string {
      build() {
        super.build();
        var self = this;

        self.flatpickrOptions = Object.assign(
          {
            dateFormat: 'd/m/Y',
            defaultDate: new Date(),
          },

          self.schema.flatpickrOptions
        );

        flatpickr(self.input, self.flatpickrOptions);
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.format === 'dynamicDate') {
        return 'customDate';
      }
    });

    JSONEditor.defaults.editors.datepicker = class DatePicker extends JSONEditor
      .defaults.editors.string {
      build() {
        super.build();
        var self = this;

        self.flatpickrOptions = Object.assign(
          {
            onClose(selectedDates, dateStr, instance) {
              self.value = dateStr;

              self.input.value = dateStr;

              self.onChange(true);
            },
            onOpen() {
              this.showTimeInput = true;
            },
          },

          self.schema.flatpickrOptions
        );

        flatpickr(self.input, self.flatpickrOptions);

        var interval = setInterval(() => {
          const val = editor.getEditor(this.path).value;
          if (val && val !== '') {
            flatpickr(self.input, self.flatpickrOptions).setDate(val);
            clearInterval(interval);
          }
        }, 1000);

        const clearButton = document.createElement('button');
        clearButton.innerHTML = 'Clear Date';
        clearButton.setAttribute('class', 'btn p-button-secondary');
        clearButton.style.margin = '0 5px 15px 0';
        clearButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          flatpickr(self.input, self.flatpickrOptions).clear();

          self.value = '';

          self.input.value = '';

          self.onChange(true);
        });

        this.container.appendChild(clearButton);

        editor.watch(self.path, () => {
          if (
            editor.getEditor(self.path) &&
            editor.getEditor(self.path).value
          ) {
            const val = editor.getEditor(self.path).value;
            if (val === '') {
              clearButton.setAttribute('disabled', 'true');
            } else {
              clearButton.removeAttribute('disabled');
            }
          }
        });
      }
    };
    JSONEditor.defaults.resolvers.unshift(function(schema) {
      if (schema.format === 'datepicker' && schema.type === 'string') {
        return 'datepicker';
      }
    });

    JSONEditor.defaults.editors.pdf = class PDF extends JSONEditor.defaults
      .editors.string {
      build() {
        super.build();
        const pdfLinkInput = this;

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Add PDF Link';
        selectButton.setAttribute('class', 'btn btn-primary');
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          self.addPdf.emit(pdfLinkInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset Pdf';
        resetButton.setAttribute('class', 'btn p-button-secondary');
        // disable for readonly conten type
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          const pdfField = editor.getEditor(pdfLinkInput.path);

          const pdfId = editor.getEditor(
            pdfLinkInput.path.replace('.filename', '.id')
          );
          if (pdfField) {
            pdfField.setValue('');
            pdfId.setValue('');
          }
        });

        this.container.appendChild(selectButton);

        this.container.appendChild(resetButton);
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsPdf') {
        return 'pdf';
      }
    });

    JSONEditor.defaults.editors.ics = class ICS extends JSONEditor.defaults
      .editors.string {
      build() {
        super.build();
        const icsLinkInput = this;

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Add ICS Link';
        selectButton.setAttribute('class', 'btn btn-primary');
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          self.addIcs.emit(icsLinkInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset ICS';
        resetButton.setAttribute('class', 'btn p-button-secondary');
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          const icsField = editor.getEditor(icsLinkInput.path);

          const icsId = editor.getEditor(
            icsLinkInput.path.replace('.filename', '.id')
          );
          if (icsField) {
            icsField.setValue('');
            icsId.setValue('');
          }
        });

        this.container.appendChild(selectButton);

        this.container.appendChild(resetButton);
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsIcs') {
        return 'ics';
      }
    });

    JSONEditor.defaults.editors.video = class Video extends JSONEditor.defaults
      .editors.string {
      build() {
        super.build();
        const videoLinkInput = this;

        const video = document.createElement('video');
        video.setAttribute('src', '');
        video.setAttribute('controls', 'true');
        video.setAttribute('preload', 'auto');

        video.setAttribute('id', 'video-' + videoLinkInput.formname);
        video.style.maxHeight = '200px';
        video.style.maxWidth = '200px';
        video.style.marginLeft = '5px';
        video.style.display = 'none';

        const selectButton = document.createElement('button');
        selectButton.innerHTML = 'Select Video';
        selectButton.setAttribute('class', 'btn btn-primary');
        selectButton.style.margin = '0 5px 15px 0';
        selectButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          self.addVideo.emit(videoLinkInput.formname);
        });

        const resetButton = document.createElement('button');
        resetButton.innerHTML = 'Reset Video';
        resetButton.setAttribute('class', 'btn p-button-secondary');
        resetButton.setAttribute('disabled', 'true');
        resetButton.style.margin = '0 5px 15px 0';
        resetButton.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          const imageField = editor.getEditor(videoLinkInput.path);
          if (imageField) {
            imageField.setValue('');
            video.setAttribute('src', '');
            video.style.display = 'none';
          }
        });

        editor.watch(videoLinkInput.path, () => {
          if (
            editor.getEditor(videoLinkInput.path) &&
            editor.getEditor(videoLinkInput.path).value
          ) {
            const videoFilename = editor.getEditor(
              videoLinkInput.path.replace('originalFileName', 'fileName')
            ).value;
            const src = `${environment.mediaUrl}/${videoFilename}`;
            video.setAttribute('src', src);
            video.style.display = 'block';
            resetButton.removeAttribute('disabled');
          } else {
            resetButton.setAttribute('disabled', 'true');
          }
        });

        const mainDiv = document.createElement('div');
        mainDiv.setAttribute('class', 'form-group');
        mainDiv.setAttribute('style', 'display: flex');
        mainDiv.appendChild(selectButton);
        mainDiv.appendChild(resetButton);
        mainDiv.appendChild(video);

        this.container.appendChild(mainDiv);
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'string' && schema.extension === 'cmsVideo') {
        return 'video';
      }
    });

    JSONEditor.defaults.editors.pressContent = class PressContent extends JSONEditor
      .defaults.editors.object {
      postBuild() {
        this.title.setAttribute('style', 'display: none');
        super.postBuild();
      }
    };
    JSONEditor.defaults.resolvers.unshift(schema => {
      if (schema.type === 'object' && schema.extension === 'pressContent') {
        return 'pressContent';
      }
    });

    const element = document.getElementById(this.editorId);
    this.jsonEditorElement = element;
    const editor = new JSONEditor(element, options);
    this.editor = editor;

    // TODO: check endpoint to perform action
    const TEMPLATE_PATH = 'root.mail_template';
    editor.watch(TEMPLATE_PATH, () => {
      const templateEditor = editor.getEditor(TEMPLATE_PATH);
      if (templateEditor) {
        const templateSlug = templateEditor.getValue();
        const template = mailTemplates.templatesContent.find(
          mailTemplate => mailTemplate.slug === templateSlug
        );
        this.templateChange.emit(template);
      }
    });

    editor.on('ready', () => {
      if (values !== null && values !== 'undefined') {
        editor.setValue(values);
      }
      editor.validate();
      if (this.contentType === 'mailTemplatesContent') {
        const sectionPath = 'root.sections';
        const imageWithLinkPath = 'root.image_with_link';

        editor.watch(sectionPath, () => {
          const sections = editor.getEditor(sectionPath).getValue();
          const imageWithLinkEditor = editor.getEditor(imageWithLinkPath);
          if (sections && sections.visible) {
            imageWithLinkEditor.setValue({ visible: false });
          }
        });

        editor.watch(imageWithLinkPath, () => {
          const imageWithLink = editor.getEditor(imageWithLinkPath).getValue();
          const sectionEditor = editor.getEditor(sectionPath);
          if (imageWithLink && imageWithLink.visible) {
            sectionEditor.setValue({
              ...sectionEditor.getValue(),
              visible: false,
            });
          }
        });

        const updateCollapseStatus = childEditor => {
          const { visible } = editor
            .getEditor(childEditor.options.path)
            .getValue();
          if (typeof visible === 'boolean') {
            const expandBtn = childEditor.collapse_control;
            childEditor.collapsed = !visible;
            if (childEditor.editor_holder) {
              if (visible) {
                childEditor.editor_holder.style.display = '';
                childEditor.setButtonText(
                  expandBtn,
                  '',
                  'collapse',
                  'Collapse'
                );
              } else {
                childEditor.editor_holder.style.display = 'none';
                childEditor.setButtonText(expandBtn, '', 'expand', 'Expand');
              }
            }
          }
        };
        Object.values(editor.editors).forEach((childEditor: any) => {
          if (childEditor.parent && childEditor.parent.key === 'root') {
            updateCollapseStatus(childEditor);
            editor.watch(
              childEditor.options.path,
              updateCollapseStatus.bind(null, childEditor)
            );
          }
        });
      }
    });

    editor.on('change', () => {
      editor.validate();
      this.checkChanges(editor.getValue());
    });

    this.editorObj.emit(this.editor);
  }

  checkChanges(content) {
    this.tempValues = content;
    this.valuesToSave.emit(content);
  }

  ngOnDestroy() {
    if (this.editor && this.jsonEditorElement) {
    this.editor.unwatch();
    this.editor.off();
    this.editor.destroy();
    // Remove all EventListners
    this.jsonEditorElement.remove();
    }
  }
}
