import { Injectable } from '@angular/core';
import { ComponentItem } from '../dynamic-components/models/component-item';
import { PostCommonFieldsComponent } from '../../cms/templates/post/components/post-common-fields/post-common-fields.component';

@Injectable()
export class TemplateHostService {
  public templates = {
    mailTemplatesContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressReviewsContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressReviewsDSContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressReviewsLaBussolaContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressBruxellesBubble: {
      commonFields: PostCommonFieldsComponent
    },
    pressReviewsAdnkContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressReleasesContent: {
      commonFields: PostCommonFieldsComponent
    },
    cioCommunicationsContent: {
      commonFields: PostCommonFieldsComponent
    },
    pressCommunicationsContent: {
      commonFields: PostCommonFieldsComponent
    },
    fincantieriSIContent: {
      commonFields: PostCommonFieldsComponent
    },
    fincantieriInfrastructureContent: {
      commonFields: PostCommonFieldsComponent
    },
    opereMarittimeContent: {
      commonFields: PostCommonFieldsComponent
    },
    finsoContent: {
      commonFields: PostCommonFieldsComponent
    },
    sofContent: {
      commonFields: PostCommonFieldsComponent
    }
  };

  getTemplate(content: string, name: string) {
    if (!this.templates.hasOwnProperty(content)) {
      return [];
    }
    if (!this.templates[content].hasOwnProperty(name)) {
      return [];
    }
    return this.templates[content][name];
  }

  loadTemplate(content: string, name: string, data: any) {
    return new ComponentItem(this.getTemplate(content, name), data);
  }
}
