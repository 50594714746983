import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(protected authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // req = req.clone({
    //   url: req.url.includes('/assets/') || req.url.startsWith('https://') ? req.url : environment.apiUrl + req.url
    // });

    return next.handle(req);
  }
}
