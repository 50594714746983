import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'reject-notes',
  templateUrl: './reject-notes.component.html',
  styleUrls: ['./reject-notes.component.scss']
})
export class RejectNotesComponent {
  @Input() displayRejectModal: boolean;

  @Output() onHideReject = new EventEmitter<any>();
  @Output() onReject = new EventEmitter<any>();
  @Output() displayRejectModalChange = new EventEmitter<any>();

  rejectNotes: string;

  onHide() {
    this.displayRejectModalChange.emit();
    this.onHideReject.emit();
    this.rejectNotes = null;
  }

  onRejectDEM() {
    this.onReject.emit(this.rejectNotes);
  }
}
